import React from "react";

export const Ract = () => {
  return (
    <>
      <svg
        width="65"
        height="68"
        viewBox="0 0 65 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.6412 0H7.20516C2.57329 0 0 2.57962 0 7.3949V61.051C0 65.3503 2.57329 67.758 7.20516 67.758H57.6412C62.273 67.758 64.8463 65.1783 64.8463 60.879V7.56688C64.6748 2.75159 62.273 0 57.6412 0ZM62.273 61.051C62.273 63.8025 60.2144 65.3503 57.6412 65.3503H7.20516C4.6319 65.3503 2.57327 63.8025 2.57327 61.051V57.4395H62.273V61.051ZM62.273 54.6879H54.2101H50.436H35.3395H31.0507H25.218H18.0129H13.5526H9.94999H2.40176V7.56688C2.40176 4.81529 4.46034 2.75159 7.0336 2.75159H57.4696C60.0429 2.75159 62.1015 4.98726 62.1015 7.56688V54.6879H62.273Z"
          fill="#222222"
        />
        <path
          d="M27.9634 46.7766L25.3901 54.6874L27.9634 46.7766Z"
          fill="black"
        />
        <path d="M13.7241 54.688V48.153V54.688H18.0129H13.7241Z" fill="black" />
        <path
          d="M17.3267 44.7136C16.8121 45.5735 15.6112 46.0894 13.7241 46.0894V41.1021V46.2614C15.6112 46.0894 16.8121 45.5735 17.3267 44.7136Z"
          fill="black"
        />
        <path
          d="M57.2979 41.9622V38.3507H47.1763V41.9622H50.2642V54.6883H54.2099V41.9622H57.2979Z"
          fill="#222222"
        />
        <path
          d="M45.9769 54.3444V51.0769C44.9476 51.4209 44.0898 51.5928 43.2321 51.4209C40.6588 51.4209 38.6002 49.8731 38.4286 46.6056C38.4286 44.7138 39.1148 41.7903 42.889 41.4463C44.2614 41.2744 44.6045 41.4463 45.9769 41.7903V38.3508C44.7761 38.0068 44.4329 38.0068 43.0605 38.0068C38.6002 37.8349 34.4829 41.9623 34.4829 46.6056C34.4829 50.733 36.7131 53.6565 40.1442 54.8603H45.1192C45.2907 54.5164 45.6338 54.3444 45.9769 54.3444Z"
          fill="#222222"
        />
        <path
          d="M13.7247 48.1526H13.8962L18.185 54.6876H18.0135H25.2187L27.9635 46.7768V46.6048L30.7083 54.5156H34.9971L27.7919 35.4265L21.273 52.6239L17.4988 47.6366C19.5574 46.6048 20.7583 44.8851 20.7583 42.9933C20.7583 40.2417 19.2143 38.178 14.2393 38.178H9.60742V54.6876H13.21V48.1526H13.7247ZM13.7247 41.1016H14.9255C17.3273 41.1016 17.8419 41.7895 17.8419 43.3373C17.8419 44.0252 17.6704 44.3691 17.4988 44.8851C16.9842 45.7449 15.7833 46.2609 13.8962 46.2609V41.1016H13.7247Z"
          fill="#222222"
        />
        <path
          d="M18.0129 54.688H18.1844L13.8957 48.153H13.7241H13.8957L18.0129 54.688Z"
          fill="#222222"
        />
        <path
          d="M17.6698 43.3378C17.6698 43.8537 17.4982 44.3697 17.3267 44.7136C17.6698 44.3697 17.6698 43.8537 17.6698 43.3378C17.6698 41.79 17.1551 41.1021 14.7534 41.1021H13.7241H14.7534C17.1551 41.1021 17.6698 41.79 17.6698 43.3378Z"
          fill="#222222"
        />
      </svg>
    </>
  );
};
