import React, { useMemo, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonText,
  IonButton,
} from "@ionic/react";
import { ResultAccordion, CountResultAccordion } from "./ModalTabAccordion";
import AccordionCandidate from "./ModalTabAccordionCandidate";
import "./ModalTab.scss";

import InsightIcon from "../components/InsightIcon";
import { AaaLogo } from "../assets/svg/aaaLogo";
import { Racq } from "../assets/svg/racq";
import { Rac } from "../assets/svg/rac";
import { Ract } from "../assets/svg/ract";
import { Raa } from "../assets/svg/raa";
import { Aant } from "../assets/svg/aant";
import { Nrma } from "../assets/svg/nrma";
import { Racv } from "../assets/svg/racv";

import { useView } from "../api/store";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { slugify } from "../utils/string/slugify";
import { CountView } from "../api/data-types";

function html(content: string) {
  return {
    __html: content,
  };
}

const ModalTabDefault = ({ electorate, parentPath, hasDetails }) => {
  const [view] = useView();
  let [asteriskText, setAsteriskText] = useState<string>("");
  let [daggerText, setDaggerText] = useState<string>("");

  let results = useMemo(() => {
    let mergedResult = (view.results as any[]).filter(
      (result: any) => slugify(result.Electorate) === electorate
    );

    if (
      mergedResult[0].hasOwnProperty("Former Name") &&
      mergedResult[0]["Former Name"] !== ""
    ) {
      let extraResults = (view.results as any[]).filter(
        (result) =>
          slugify(result.Electorate) === slugify(mergedResult[0]["Former Name"])
      );

      extraResults.forEach((result) => {
        let extra = { ...result };
        extra.Election = extra.Election + "*";
        mergedResult.push(extra);
      });
    }

    mergedResult.forEach((result) => {
      if (result.Byelection) {
        setDaggerText("† results from this year are from a by-election");
      }

      if (
        result.hasOwnProperty("Former Name") &&
        result["Former Name"] !== ""
      ) {
        setAsteriskText(
          "* results from the seat of " +
            result["Former Name"] +
            " prior to redistribution"
        );
      }
    });

    return mergedResult;
  }, [view, electorate]);

  let priority = useMemo(() => {
    return view.priorities.find(
      (prio) => slugify(prio.Electorate) === electorate
    );
  }, [view, electorate]);

  let candidates = useMemo(() => {
    return view.candidates.filter(
      (candidate) => slugify(candidate.electorate) === electorate
    );
  }, [view, electorate]);

  const returnDescription = () => {
    if (priority) return html(priority["Description"]);
  };

  const returnHistory = () => {
    if (priority) return html(priority["History"]);
  };

  const returnPriorities = () => {
    return html(priority["Priorities"]);
  };

  const renderDetails = () => (
    <div className="modal-tab details">
      {(priority["Insight High Traffic Congestion"] ||
        priority["Insight High Fuel Cost"]) && (
        <div className="insights">
          <h2>Insights</h2>
          {priority["Insight High Traffic Congestion"] && (
            <div className="insight">
              <InsightIcon type="traffic" />
              <IonText color="warning">High Traffic Congestion</IonText>
            </div>
          )}

          {priority["Insight High Fuel Cost"] && (
            <div className="insight">
              <InsightIcon type="fuel" />
              <IonText color="warning">High Fuel Cost</IonText>
            </div>
          )}
        </div>
      )}

      {priority?.["Description"] && (
        <div className="text-block">
          <h2>Description</h2>
          <div dangerouslySetInnerHTML={returnDescription()} />
        </div>
      )}

      {priority?.["History"] && (
        <div className="text-block">
          <h2>History</h2>
          <div dangerouslySetInnerHTML={returnHistory()} />
        </div>
      )}
    </div>
  );

  const renderResults = () => (
    <div className={"modal-tab results"}>
      <h2>Electoral History</h2>
      <IonList lines="full">
        {results.map((result, key) => (
          <IonItem key={key}>
            <ResultAccordion result={result} />
          </IonItem>
        ))}
      </IonList>

      {asteriskText !== "" && (
        <IonText className="footnote">{asteriskText}</IonText>
      )}
      {daggerText !== "" && (
        <IonText className="footnote">{daggerText}</IonText>
      )}

      {results.map((result, key) => {
        if (result["Notional TPP"]) {
          return (
            <IonText key={key} className="footnote">
              # refers to the {result.Election} notional two party preferred
              result for redistributed electorates.
            </IonText>
          );
        }
        return <React.Fragment key={key}></React.Fragment>;
      })}
    </div>
  );

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <Switch>
            <Route path={`${parentPath}/results`} render={renderResults} />
            {hasDetails && (
              <Route path={`${parentPath}/details`} render={renderDetails} />
            )}
            {(!!candidates.length || view.id !== "FED") && (
              <Route
                path={`${parentPath}/candidates`}
                render={() => (
                  <div className={"modal-tab candidates"}>
                    <h2>Candidates</h2>
                    <IonList lines="full">
                      {candidates.map((candidate, key) => (
                        <IonItem key={key}>
                          <AccordionCandidate candidate={candidate} />
                        </IonItem>
                      ))}
                    </IonList>
                  </div>
                )}
              />
            )}
            {priority?.Priorities && (
              <Route
                path={`${parentPath}/priorities`}
                render={() => (
                  <>
                    <div className={"modal-tab priorities"}>
                      <div className="text-block single">
                        {view.strings.map((result, key) => {
                          if (result.Name === "Priority Title") {
                            if (typeof result.String !== "undefined")
                              return (
                                <React.Fragment key={key}>
                                  <h2>{result.String}</h2>
                                </React.Fragment>
                              );
                          }
                          return <React.Fragment key={key}></React.Fragment>;
                        })}
                        <div dangerouslySetInnerHTML={returnPriorities()} />
                      </div>
                    </div>

                    {view.id === "QLD" && (
                      <div className="modal-tab logos">
                        <IonText>
                          For media queries about these projects, contact the
                          RACQ media team.
                        </IonText>
                        <IonButton
                          expand="block"
                          href="https://www.racq.com.au/Live/Articles/contact-us"
                          target="_blank"
                        >
                          Contact RACQ
                        </IonButton>
                      </div>
                    )}

                    {view.id === "WA" && (
                      <div className="modal-tab logos">
                        <IonText>
                          For media queries about these projects, contact the
                          RAC media team.
                        </IonText>
                        <IonButton
                          expand="block"
                          href="https://rac.com.au/about-rac/media"
                          target="_blank"
                        >
                          Contact RAC
                        </IonButton>
                      </div>
                    )}
                  </>
                )}
              />
            )}
            <Route path="*" exact render={renderResults} />
          </Switch>

          <div className="modal-tab logos">
            <IonText>Proudly brought to you by</IonText>
            <div className="logo-block">
              <AaaLogo />
              {view.id === "QLD" && <Racq />}
              {view.id === "WA" && <Rac />}
              {view.id === "TAS" && <Ract />}
              {view.id === "SA" && <Raa />}
              {view.id === "NT" && <Aant />}
              {view.id === "ACT" && <Nrma />}
              {view.id === "NSW" && <Nrma />}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const ModalTabCount = ({ parentPath, electorate, hasDetails }) => {
  const [view] = useView() as [CountView, any];
  let [asteriskText, setAsteriskText] = useState<string>("");
  let [daggerText, setDaggerText] = useState<string>("");

  let candidates = useMemo(() => {
    return view.candidates.filter(
      (candidate) => slugify(candidate.electorate) === electorate
    );
  }, [view, electorate]);

  let totals = useMemo(() => {
    const filtered = view.countTotals.filter(
      (result) => result.Electorate.toLowerCase() === electorate.toLowerCase()
    );

    const years = [...new Set(filtered.map((result) => result.Election))];

    const mergedTotals = years
      .sort()
      .reverse()
      .map((year) => {
        let merged = {} as any;

        filtered.forEach((election) => {
          if (election.Election === year) {
            merged[year] = {};
          }
        });

        // filtered.forEach((election) => {
        //   if (election.Election === year) {
        //     merged[year][election.Party] = {
        //       ["Party"]: election.Party,
        //       ["After-distribution Quotas"]:
        //         election["After-distribution Quotas"],
        //       ["Elected Members"]: election["Elected Members"],
        //       ["Quota Order"]: election["Quota Order"],
        //       ["Share of Primary Vote"]: election["Share of Primary Vote"],
        //     };
        //   }
        // });

        return merged;
      });

    return mergedTotals;
  }, [view, electorate]);

  let results = useMemo(() => {
    let mergedResult = (view.results as any[]).filter(
      (result: any) => slugify(result.Electorate) === electorate
    );

    if (
      mergedResult[0].hasOwnProperty("Former Name") &&
      mergedResult[0]["Former Name"] !== ""
    ) {
      let extraResults = (view.results as any[]).filter(
        (result) =>
          slugify(result.Electorate) === slugify(mergedResult[0]["Former Name"])
      );

      extraResults.forEach((result) => {
        let extra = { ...result };
        extra.Election = extra.Election + "*";
        mergedResult.push(extra);
      });
      setAsteriskText(
        "* results from the seat of " +
          mergedResult[0]["Former Name"] +
          " prior to redistribution"
      );
    }

    mergedResult.forEach((result) => {
      if (result.Byelection) {
        setDaggerText("† results from this year are from a by-election");
      }
    });

    return mergedResult;
  }, [view, electorate]);

  let priority = useMemo(() => {
    return view.priorities.find(
      (prio) => slugify(prio.Electorate) === electorate
    );
  }, [view, electorate]);

  const returnDescription = () => {
    if (priority) return html(priority["Description"]);
  };

  const returnHistory = () => {
    if (priority) return html(priority["History"]);
  };

  const returnPriorities = () => {
    return html(priority["Priorities"]);
  };

  const renderDetails = () => (
    <div className="modal-tab details">
      {(priority["Insight High Traffic Congestion"] ||
        priority["Insight High Fuel Cost"]) && (
        <div className="insights">
          <h2>Insights</h2>
          {priority["Insight High Traffic Congestion"] && (
            <div className="insight">
              <InsightIcon type="traffic" />
              <IonText color="warning">High Traffic Congestion</IonText>
            </div>
          )}

          {priority["Insight High Fuel Cost"] && (
            <div className="insight">
              <InsightIcon type="fuel" />
              <IonText color="warning">High Fuel Cost</IonText>
            </div>
          )}
        </div>
      )}

      {priority?.["Description"] && (
        <div className="text-block">
          <h2>Description</h2>
          <div dangerouslySetInnerHTML={returnDescription()} />
        </div>
      )}

      {priority?.["History"] && (
        <div className="text-block">
          <h2>History</h2>
          <div dangerouslySetInnerHTML={returnHistory()} />
        </div>
      )}
    </div>
  );

  const renderResults = () => (
    <div className={"modal-tab results"}>
      <h2 className="withKey">Electoral History</h2>
      <div className="key">
        <IonText style={{ fontSize: "12px" }}>
          <span className="reversed">1</span> Elected candidate order
        </IonText>
        <IonText style={{ fontSize: "12px" }}>
          <span className="notreversed">1</span> Sitting candidate order
        </IonText>
      </div>

      <IonList lines="full">
        {totals.map((total, key) => (
          <IonItem key={key}>
            <CountResultAccordion
              electorate={electorate.toLowerCase()}
              election={Object.keys(total)[0]}
              results={results.filter(
                (year) => year.Election === Object.keys(total)[0]
              )}
            />
          </IonItem>
        ))}
      </IonList>
      {asteriskText !== "" && (
        <IonText className="footnote">{asteriskText}</IonText>
      )}
      {daggerText !== "" && (
        <IonText className="footnote">{daggerText}</IonText>
      )}
    </div>
  );

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          <Switch>
            <Route path={`${parentPath}/results`} render={renderResults} />
            {hasDetails && (
              <Route path={`${parentPath}/details`} render={renderDetails} />
            )}

            <Route
              path={`${parentPath}/candidates`}
              render={() => (
                <div className={"modal-tab candidates"}>
                  <h2>Candidates</h2>
                  <IonList lines="full">
                    {candidates.map((candidate, key) => (
                      <IonItem key={key}>
                        <AccordionCandidate candidate={candidate} />
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              )}
            />
            {priority?.Priorities && (
              <Route
                path={`${parentPath}/priorities`}
                render={() => (
                  <>
                    <div className={"modal-tab priorities"}>
                      <div className="text-block single">
                        {view.strings.map((result, key) => {
                          if (result.Name === "Priority Title") {
                            if (typeof result.String !== "undefined")
                              return (
                                <React.Fragment key={key}>
                                  <h2>{result.String}</h2>
                                </React.Fragment>
                              );
                          }
                          return <React.Fragment key={key}></React.Fragment>;
                        })}

                        <div dangerouslySetInnerHTML={returnPriorities()} />
                      </div>
                    </div>

                    {view.id === "QLD" && (
                      <div className="modal-tab logos">
                        <IonText>
                          For media queries about these projects, contact the
                          RACQ media team.
                        </IonText>
                        <IonButton
                          expand="block"
                          href="https://www.racq.com.au/Live/Articles/contact-us"
                          target="_blank"
                        >
                          Contact RACQ
                        </IonButton>
                      </div>
                    )}

                    {view.id === "WA" && (
                      <div className="modal-tab logos">
                        <IonText>
                          For media queries about these projects, contact the
                          RAC media team.
                        </IonText>
                        <IonButton
                          expand="block"
                          href="https://rac.com.au/about-rac/media"
                          target="_blank"
                        >
                          Contact RAC
                        </IonButton>
                      </div>
                    )}
                  </>
                )}
              />
            )}
            <Route path="*" exact render={renderResults} />
          </Switch>

          <div className="modal-tab logos">
            <IonText>Proudly brought to you by</IonText>
            <div className="logo-block">
              <AaaLogo />
              {view.id === "QLD" && <Racq />}
              {view.id === "WA" && <Rac />}
              {view.id === "TAS" && <Ract />}
              {view.id === "SA" && <Raa />}
              {view.id === "NT" && <Aant />}
              {view.id === "ACT" && <Nrma />}
              {view.id === "NSW" && <Nrma />}
              {view.id === "VIC" && <Racv />}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const ModalTab = ({ parentPath, electorate, hasDetails }) => {
  let [view] = useView();
  if (view.mode === "default") {
    return (
      <ModalTabDefault
        parentPath={parentPath}
        electorate={electorate}
        hasDetails={hasDetails}
      />
    );
  } else {
    return (
      <ModalTabCount
        parentPath={parentPath}
        electorate={electorate}
        hasDetails={hasDetails}
      />
    );
  }
};

export default ModalTab;
