import React from 'react'

export const IconDownloadPDF = () => {
  return (
    <>
      <svg width="48" height="17" viewBox="0 0 48 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.821533 13H2.31753V10.316H4.16553C4.30853 10.316 4.61653 10.305 4.82553 10.272C6.23353 10.052 6.87153 9.02902 6.87153 7.69802C6.87153 6.36702 6.24453 5.34402 4.82553 5.12402C4.61653 5.09102 4.31953 5.08002 4.16553 5.08002H0.821533V13ZM2.31753 8.91902V6.47702H4.09953C4.25353 6.47702 4.45153 6.48802 4.60553 6.53202C5.21053 6.69702 5.36453 7.26902 5.36453 7.69802C5.36453 8.12702 5.21053 8.69902 4.60553 8.86402C4.45153 8.90802 4.25353 8.91902 4.09953 8.91902H2.31753Z" fill="#222222"/>
        <path d="M8.52583 13H11.0888C11.2098 13 11.6718 13 12.0348 12.956C13.9488 12.725 15.0158 11.053 15.0158 9.04002C15.0158 7.02702 13.9488 5.35502 12.0348 5.12402C11.6718 5.08002 11.2098 5.08002 11.0888 5.08002H8.52583V13ZM10.0438 11.592V6.48802H11.0888C11.2868 6.48802 11.6718 6.48802 11.9468 6.54302C12.9478 6.74102 13.4318 7.85202 13.4318 9.04002C13.4318 10.173 12.9808 11.328 11.9468 11.537C11.6718 11.592 11.2868 11.592 11.0888 11.592H10.0438Z" fill="#222222"/>
        <path d="M16.8854 13H18.3814V9.78802H21.0654V8.29202H18.3814V6.57602H21.7254V5.08002H16.8854V13Z" fill="#222222"/>
        <path d="M30 11H32V15H46V11H48V17H30V11Z" fill="#222222"/>
        <path d="M39 0V11" stroke="black" strokeWidth="2"/>
        <path d="M35 8L39 12L43 8" stroke="black" strokeWidth="2"/>
      </svg>
    </>
  )
}
