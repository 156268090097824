import React from 'react'

export const IconTrafficCongestion = () => {
  return (
    <>
      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.77778 7.77778L8.33333 10H10V11.6667H0V10H1.66667L2.22222 7.77778H7.77778ZM6.66667 3.88889L7.22222 6.11111H2.77778L3.33333 3.88889H6.66667ZM5.55556 0L6.11111 2.22222H3.88889L4.44444 0H5.55556Z" fill="white"/>
</svg>
    </>
  )
}