import React from 'react'
import {
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonContent,
} from '@ionic/react'

import { IconMap } from '../../assets/svg/map'
import { IconDirectory } from '../../assets/svg/directory'
import { IconPendulum } from '../../assets/svg/pendulum'

import Hamburger from '../../components/Hamburger'
import Drawer from '../../components/Drawer'

import './tabs.scss'
import './Segments.scss'
import { Redirect, Route, useHistory, useLocation } from 'react-router'
import Map from './map/Map'
import Directory from './directory/Directory'
import Pendulum from './pendulum/Pendulum'
import MapModal from '../../components/MapModal'
import { useSelectable } from '../../utils/state/selectable'
import { store } from '../../api/store'
import { Loader } from '../../components/loader/loader'

function RouteWrapper() {
  return (
    <IonContent>
      <Hamburger />
      <Drawer />
      <Route path='/:page/electorate/:electorate/:tab?' component={MapModal} />
      <Route path='/map' exact component={Map} />
      <Route path='/directory' exact component={Directory} />
      <Route path='/pendulum' exact component={Pendulum} />
    </IonContent>
  )
}

export function CurrentView() {
  let history = useHistory()
  let location = useLocation()
  const view = useSelectable(store, (state) => state.view)
  const hasView = useSelectable(store, (state) => !!state.views[state.view])
  const mode = useSelectable(store, (state) => state.views[state.view]?.mode)

  function navigateTo(url: string) {
    return () => {
      history.push(url)
    }
  }

  if (!view) {
    return <Redirect to='/onboarding' />
  }

  if (!hasView) {
    return <Loader />
  }

  if (
    location.pathname.search(/map/g) === -1 &&
    location.pathname.search(/directory/g) === -1 &&
    location.pathname.search(/pendulum/g) === -1
  ) {
    location.pathname = '/map'
  }

  return (
    <>
      {location.pathname !== '/' && (
        <>
          <RouteWrapper />
          <IonToolbar>
            <IonSegment
              mode='md'
              className='main-nav'
              value={location.pathname.split('/')[1]}
            >
              <IonSegmentButton value='map' onClick={navigateTo('/map')}>
                <IconMap />
                <IonLabel>Map</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value='directory'
                onClick={navigateTo('/directory')}
              >
                <IconDirectory />
                <IonLabel>Directory</IonLabel>
              </IonSegmentButton>
              {mode !== 'count' && (
                <IonSegmentButton
                  value='pendulum'
                  onClick={navigateTo('/pendulum')}
                >
                  <IconPendulum />
                  <IonLabel>Pendulum</IonLabel>
                </IonSegmentButton>
              )}
            </IonSegment>
          </IonToolbar>
        </>
      )}
    </>
  )
}
