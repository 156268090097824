import React from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import './Map.scss'
import LeafletMap from '../../../components/LeafletMap'
import SearchList from '../../../components/SearchList'
import { useView } from '../../../api/store'

const Map: React.FC<any> = ({ zoomMap }) => {
  const [electorates, set] = useView((view) => view.electorates)

  const selectFromList = (electorate) => {
    let found = electorates['features'].find((feature) => {
      return feature.properties.NAME.toLowerCase() === electorate.toLowerCase()
    })

    if (found) {
      set((state) => {
        state.focus = electorate
      })
    }
  }

  const getModalData = (electorate) => {
    set((view) => {
      view.focus = electorate
      view.modal = {
        electorate,
      }
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Map</IonTitle>
        </IonToolbar>
      </IonHeader>

      <SearchList select={selectFromList} />

      <IonContent>
        <LeafletMap getModalData={getModalData} />
      </IonContent>
    </IonPage>
  )
}

export default Map
