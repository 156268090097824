import { Selectable, useSelectable } from '../utils/state/selectable'
import { browserPlugins } from './browser-plugins'
import { View, State, CountView } from './data-types'
import { loadFromJSON } from './load-from-json'
import { preferences } from './preferences'

type Callback<T = any, R = any> = (arg: T) => R
// Test

export const store = new Selectable<State>({
  view: undefined,
  menuOpen: false,
  loadedAt: 0,
  loadingProgress: 0,
  loadingViews: {},
  views: {},
})

browserPlugins(store)

export function load() {
  return Promise.resolve(store.suspended).then(async () => {
    const { useAirtable, ignoreCache } = preferences.state

    if (ignoreCache) {
      store.set((state) => {
        state.views = {}
      })
    }

    // const DAY = 1000 * 60 * 24
    // const dataIsOlderThanDay = 0.5 * DAY < Date.now() - store.state.loadedAt
    const dataIsOlderThanDay = true
    const loadNewData = dataIsOlderThanDay || ignoreCache || useAirtable

    /* If there is no result from cache */
    if (loadNewData) {
      if (useAirtable /* || window.location.hostname === 'localhost' */) {
        console.log('LOADING DATA FROM AIRTABLE')
        /* Only load the load from airtable method on localhost and adminishy accounts */
        let { loadFromAirtable } = await import('./load-from-airtable')
        await loadFromAirtable()
      } else {
        console.log('LOADING DATA FROM JSON')
        try {
          let start = Date.now()
          await loadFromJSON()
          if (Date.now() - start < 1500) {
            await new Promise<void>((resolve) => {
              setTimeout(() => {
                resolve()
              }, 300)
            })
          }
        } catch (e) {
          console.log('LOADING FROM JSON FAILED', e)
          /* Only load the load from airtable method on localhost and adminishy accounts */
          if (!store.state.views.QLD) {
            console.log('LOADING DATA FROM AIRTABLE')
            let { loadFromAirtable } = await import('./load-from-airtable')
            await loadFromAirtable()
          }
        }
      }
    }
  })
}

export function useView<T = View | CountView>(
  selector?: Callback<View | CountView, T> | undefined
) {
  let data = useSelectable(store, (state) => {
    let currentView = state.views[state.view]
    return selector ? selector(currentView) : currentView
  })
  function set(setter: (arg: View | CountView) => any) {
    store.set((state) => {
      return setter(state.views[state.view])
    })
  }
  return [data, set] as [
    typeof data extends View | CountView | T ? T : View,
    typeof set
  ]
}
