import React from 'react'

export const IconClose = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.6465 11.3536L8.00001 8.70711L5.35356 11.3536L4.64645 10.6465L7.2929 8.00001L4.64645 5.35356L5.35356 4.64645L8.00001 7.2929L10.6465 4.64645L11.3536 5.35356L8.70711 8.00001L11.3536 10.6465L10.6465 11.3536Z"
        fill="black"
      />
    </svg>
  )
}
