import React from "react";

export const Aant = () => {
  return (
    <>
      <svg
        width="789"
        height="190"
        viewBox="0 0 789 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.81 123.13C32.25 97.08 9.14 55.66 0 0H40.02C48 41.65 65.32 72.26 91.68 91.04C95.3235 93.6366 99.1338 96.005 103.109 98.1452L144.87 0H182.96L228.098 106.064C284.313 95.8732 326.837 97.1454 361.151 112.302L361.15 112.3L335.6 49.63L314.85 100.53C302.37 99.26 288.97 99.14 274.5 100.09L317.1 0H355.19L431.15 178.5L431.173 178.553C434.522 185.025 435.67 189.13 435.67 189.13H392.49H392.48L392.462 189.085C361.703 147.38 328.173 129.476 243.941 143.29L249.26 155.79C265.64 153.24 278.97 151.99 293.44 151.99L278.33 189.13H220.26L204.378 150.171C149.715 157.397 104.188 148.33 68.81 123.13ZM188.924 112.264C170.613 113.917 153.818 113.303 138.598 110.431L163.39 49.63L188.924 112.264ZM481.17 0L569.48 116.26V0H610.25V189.13H574.4L484.17 70.46V189.13H443.39V0H481.17ZM632.51 0V38.09H689.65V189.13H730.96V38.09H788.11V0H632.51ZM106.5 189.13H64.3799L82.3599 146.9C91.2799 151.35 105.48 157.03 118.39 160.02L106.5 189.13Z"
          fill="#222222"
        />
      </svg>
    </>
  );
};
