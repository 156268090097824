import React from "react";
import { IonText } from "@ionic/react";
import { useView } from "../../api/store";
import { CountView } from "../../api/data-types";
import "./Graph.scss";

export function Graph({ result, rounded = true }) {
  let runnerUpParty = result["Runner-up Party (TCP)"]
    ?.toLowerCase()
    .replace(" (np)", "");
  let electedParty = result["Elected Party (TCP)"]
    .toLowerCase()
    .replace(" (np)", "");
  return (
    <div className={"graph " + (rounded ? "--rounded" : "")}>
      <div
        className="bar-graph"
        style={{ color: `var(--party-${runnerUpParty})` }}
      >
        <span
          className="bar-win"
          style={{
            color: `var(--party-${electedParty})`,
            width: 50 + result["TCP Margin"] + "%",
          }}
        ></span>
        <div className="labels">
          {/* Win */}
          <IonText>
            {(50 + result["TCP Margin"]).toFixed(2)}
            <strong>{electedParty}</strong>
          </IonText>
          {/* Loss */}
          <IonText>
            <strong>{runnerUpParty}</strong>
            {(50 - result["TCP Margin"]).toFixed(2)}
          </IonText>
        </div>
      </div>
      <span className="half"></span>
    </div>
  );
}

export function CountGraph({ electorate, year, rounded = true }) {
  const [view] = useView() as [CountView, any];

  const elec = electorate.toLowerCase();
  const current = view.countTotals
    .filter(
      (result) =>
        (parseInt(result.Election.substring(2, 4)) === year ||
          parseInt(result.Election.substring(2, 4)) ===
            parseInt(year.toString().substring(2, 4))) &&
        result.Electorate.toLowerCase() === elec &&
        result["After-distribution Quotas"] !== 0 &&
        !result.Election.includes("A")
    )
    .sort((a, b) => {
      return a["Quota Order"] - b["Quota Order"];
    });

  return (
    current && (
      <div className={"graph --rounded"} style={{ width: "100%" }}>
        <div className="count-bar-graph">
          {current.map(
            (result, key) =>
              result["After-distribution Quotas"] &&
              result.Party && (
                <div
                  className="result"
                  key={key}
                  style={{
                    width: `calc(100% * (${result["After-distribution Quotas"]} / 6))`,
                    background: `var(--party-${result.Party.toLowerCase()})`,
                  }}
                >
                  <IonText
                    style={{
                      padding: "0 0.5em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${result.Party} ${(
                      Math.round(result["After-distribution Quotas"] * 100) /
                      100
                    ).toFixed(2)}`}
                  </IonText>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
}
