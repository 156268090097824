import React, { useMemo, Fragment } from "react";
import { IonGrid, IonRow, IonCol, IonButton, IonText } from "@ionic/react";
import "./MapMiniContent.scss";
import { IconClose } from "../assets/svg/close";
import InsightIcon from "../components/InsightIcon";
import { useView } from "../api/store";
import { slugify } from "../utils/string/slugify";
import { useHistory } from "react-router";
import { Graph, CountGraph } from "./graph/Graph";
import { CountView, View } from "../api/data-types";

function IncumbentPopup({
  onClose,
  incumbent,
  priority,
}: {
  onClose: any;
  incumbent: View["incumbents"][number];
  priority: any;
}) {
  let history = useHistory();

  return (
    <IonGrid className={"popover"}>
      <div className="icon-close" onClick={onClose}>
        <IconClose />
      </div>
      <IonRow>
        <IonCol size="12">
          <div className="title">
            <span>
              {incumbent.Electorate}, {incumbent["State"]}
            </span>
            {priority?.["Insight High Traffic Congestion"] && (
              <InsightIcon type="traffic" />
            )}

            {priority?.["Insight High Fuel Cost"] && (
              <InsightIcon type="fuel" />
            )}
          </div>
        </IonCol>

        <IonCol size="9">
          <IonText>
            {incumbent["Elected Candidate (TCP) [Incumbent]"]} -{" "}
            {incumbent["Elected Party (TCP) [Incumbent]"]}
          </IonText>
        </IonCol>

        <IonCol size="3">
          <IonText className="text-right">
            {incumbent["TCP Margin [Incumbent]"].toFixed(2)}%
          </IonText>
        </IonCol>

        <IonCol size="12">
          <Graph result={incumbent} />
        </IonCol>

        <IonCol size="12">
          <IonButton
            expand="block"
            color="primary"
            onClick={(e) => {
              history.push(
                // @ts-ignore
                `/map/electorate/${slugify(incumbent.Electorate)}`
              );
            }}
          >
            View Electorate
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

function ElectorateCountPopup({
  electorate,
  onClose,
  priority,
}: {
  electorate: string;
  onClose: any;
  priority: any;
}) {
  let history = useHistory();
  const [view] = useView() as [CountView, any];

  const maxYear = useMemo(
    () =>
      view.results.reduce(
        (last, next) => Math.max(parseInt(next.Election.substring(1, 4)), last),
        0
      ),
    [view.results]
  );
  const results = {};
  const elec = electorate.toLowerCase();
  // const current = view.results
  //   .filter(
  //     (result) =>
  //       parseInt(result.Election.substring(1, 4)) === maxYear &&
  //       result.Electorate.toLowerCase() === elec &&
  //       !result.Election.includes("A")
  //   )
  //   .sort((a, b) => {
  //     return a["Candidate Order"] - b["Candidate Order"];
  //   })
  //   .forEach((result) => {
  //     if (result["Candidate Sitting"]) {
  //       results[result["Candidate Party"]] =
  //         (results[result["Candidate Party"]] || 0) + 1;
  //     }
  //   });

  return (
    <IonGrid className={"popover"}>
      <div className="icon-close" onClick={onClose}>
        <IconClose />
      </div>
      <IonRow>
        <IonCol size="12">
          <div className="title">
            <span>
              {electorate}, {view.id}
            </span>
            {priority?.["Insight High Traffic Congestion"] && (
              <InsightIcon type="traffic" />
            )}

            {priority?.["Insight High Fuel Cost"] && (
              <InsightIcon type="fuel" />
            )}
          </div>
        </IonCol>

        <IonCol size="12">
          <IonText>
            Candidates elected{" "}
            {Object.entries(results).map(([party, count], i) => {
              return (
                <Fragment key={i}>
                  <span className={`--party-${party.toLowerCase()}`}>
                    {party} {count}
                  </span>
                  {i !== Object.keys(results).length - 1 && <span> / </span>}
                </Fragment>
              );
            })}
          </IonText>
        </IonCol>

        <IonCol size="12">
          <CountGraph electorate={elec} year={maxYear} />
          <IonText>Quota breakdown after distribution</IonText>
        </IonCol>

        <IonCol size="12">
          <IonButton
            expand="block"
            color="primary"
            onClick={(e) => {
              history.push(
                // @ts-ignore
                `/map/electorate/${slugify(electorate)}`
              );
            }}
          >
            View Electorate
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

const MapMiniContent = ({ onClose, onExpand }) => {
  let [view] = useView();

  let priority = useMemo(() => {
    if (!view.focus) return false;
    if (!view.priorities[0]["Electorate"]) return false;
    return view.priorities.find(
      (prio) => prio.Electorate.toLowerCase() === view.focus.toLowerCase()
    );
  }, [view]);

  let incumbent = useMemo(() => {
    if (!view.focus) return false;
    if (view.mode === "default") {
      return view.incumbents.find(
        (incumbent) =>
          incumbent.Electorate.toLowerCase() === view.focus.toLowerCase()
      );
    } else if (view.mode === "count") {
      return undefined;
    }
  }, [view]);

  return (
    <>
      {view.mode === "default" && incumbent && (
        <IncumbentPopup
          incumbent={incumbent}
          onClose={onClose}
          priority={priority}
        />
      )}
      {view.mode === "count" && (
        <ElectorateCountPopup
          electorate={view.focus}
          onClose={onClose}
          priority={priority}
        />
      )}
    </>
  );
};

export default MapMiniContent;
