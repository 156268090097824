import type { Selectable } from '../utils/state/selectable'
import localforage from 'localforage'

type Opts<T, V> = {
  key: string
  localStorage?: boolean
  selector?: (state: T) => V
  setter?: (state: T, value: V) => any
  timeout?: number
}
export const LocalStoragePlugin = <T, V>(opts: Opts<T, V>) => {
  const {
    key,
    selector = (v) => v,
    localStorage: useLocalStorage = false,
    setter = (state, value) => value,
    timeout = 300,
  } = opts
  let tm
  let current
  let store = localforage.createInstance(
    useLocalStorage
      ? {
          driver: localforage.LOCALSTORAGE,
        }
      : undefined
  )
  return (selectable: Selectable<T>) => {
    function hookSelectable() {
      // @ts-ignore
      selectable.select(selector, (value) => {
        current = value
        if (timeout) {
          if (!tm) {
            tm = setTimeout(() => {
              tm = undefined
              if (current === undefined) {
                store.removeItem(key)
              } else {
                store.setItem(key, current)
              }
            }, timeout)
          }
        } else {
          if (current === undefined) {
            store.removeItem(key)
          } else {
            store.setItem(key, current)
          }
        }
      })
    }

    if (useLocalStorage) {
      let val = parse(localStorage.getItem(`localforage/${key}`))
      if (val !== null) {
        selectable.set((state) => {
          return setter(state, val as V)
        })
      }
      hookSelectable()
    } else {
      let result = new Promise<void>((resolve) => {
        store.getItem(key, (err, val) => {
          if (val !== null) {
            selectable.set((state) => {
              return setter(state, val as V)
            })
          }
          resolve()
        })
      })

      selectable.suspend(result).then(() => {
        hookSelectable()
      })
    }
  }
}

function parse(str) {
  if (!str) return null
  try {
    return JSON.parse(str)
  } catch (er) {
    return null
  }
}
