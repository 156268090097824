import React from "react";

export const Raa = () => {
  return (
    <>
      <svg
        width="106"
        height="30"
        viewBox="0 0 106 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.8879 0.0743713H86.377C85.7841 0.0743713 85.3888 0.471235 85.1911 0.8681L77.6801 20.7113L70.1692 0.8681C69.9716 0.272803 69.5761 0.0743713 68.9832 0.0743713H61.4723C60.8793 0.0743713 60.484 0.471235 60.2864 0.8681L50.7988 26.069L46.4504 19.1238C49.4152 17.5364 51.3918 14.5599 51.3918 10.5913C51.3918 4.04302 47.0433 0.272804 40.7183 0.272804H28.266C27.4753 0.272804 26.8823 0.868102 26.8823 1.66183V27.6564C26.8823 28.4502 27.4753 29.0454 28.266 29.0454H34.5909C35.3816 29.0454 35.9746 28.4502 35.9746 27.6564V20.7113H38.1487L42.4973 28.2517C42.6949 28.6486 43.0902 28.8471 43.6832 28.8471H58.1121C58.705 28.8471 59.1003 28.4502 59.298 28.0533L60.484 24.8784H70.5645L71.7504 28.0533C71.948 28.6486 72.3434 28.8471 72.9363 28.8471H83.0168C83.6098 28.8471 84.0052 28.4502 84.2028 28.0533L85.3888 24.8784H95.4692L96.6551 28.0533C96.8528 28.6486 97.2481 28.8471 97.8411 28.8471H104.166C104.957 28.8471 105.55 28.2517 105.55 27.458C105.55 27.2596 105.55 27.0612 105.55 27.0612L95.8646 1.06653C95.074 0.471235 94.4809 0.0743713 93.8879 0.0743713ZM39.5324 13.3694H35.7769V7.6148H39.5324C41.9043 7.6148 42.6949 8.80539 42.6949 10.5913C42.6949 12.3772 41.7067 13.3694 39.5324 13.3694ZM62.8558 17.3379L65.2277 10.7897L67.5997 17.3379H62.8558ZM87.7605 17.3379L90.1325 10.7897L92.5044 17.3379H87.7605Z"
          fill="#222222"
        />
        <path
          d="M12.6506 26.0691L6.52326 15.5521L0.395907 26.0691C0.000590564 26.8628 0.198224 27.8549 0.988856 28.4503C1.18651 28.6486 1.58181 28.6486 1.77948 28.6486H10.8717C11.2671 28.6486 11.8601 28.4503 12.2554 28.0534C12.8483 27.6565 13.0459 26.6644 12.6506 26.0691Z"
          fill="#222222"
        />
        <path
          d="M20.7552 13.1708L13.8372 1.26483C13.4419 0.669539 12.849 0.272675 12.0583 0.272675H2.37319C1.38491 0.272675 0.594238 1.0664 0.594238 2.05857C0.594238 2.45543 0.59425 2.65386 0.791906 3.05072L7.3146 14.5598L13.8372 26.0688C14.2325 26.6641 14.2325 27.4578 13.8372 28.0532L20.7552 15.9488C21.1505 14.9566 21.1505 13.9645 20.7552 13.1708Z"
          fill="#222222"
        />
      </svg>
    </>
  );
};
