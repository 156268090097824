import { Selectable } from '../utils/state/selectable'
import { State } from './data-types'
import { LocalStoragePlugin } from './selectable-plugin-localstorage'

export function browserPlugins(store: Selectable<any>) {
  if (typeof window !== 'undefined') {
    console.log('USING LOCAL DATA')
    store.use(
      LocalStoragePlugin({
        key: 'APP_STATE',
        selector: (state: State) => state.views,
        setter: (state: State, value) => {
          state.views = value
        },
        timeout: 0,
      })
    )
    store.use(
      LocalStoragePlugin({
        key: 'CHOSEN_LOC',
        selector: (state: State) => state.view,
        setter: (state: State, value) => {
          state.view = value
        },
        timeout: 0,
      })
    )
    store.use(
      LocalStoragePlugin({
        key: 'DOWNLOADED_AT',
        selector: (state: State) => state.loadedAt,
        setter: (state: State, value) => {
          state.loadedAt = value
        },
        timeout: 0,
        localStorage: true,
      })
    )
  }

  return store
}
