import produce from "immer";
import { useLayoutEffect, useState } from "react";

type Callback<T = any, R = any> = (arg: T) => R;
export type SelectablePlugin = (selectable: Selectable<any>) => any;

export function strictEqual(a: any, b: any) {
  return a === b;
}

export function shallowEqualArray(a: any[], b: any[]) {
  if (a.length !== b.length) return false;
  return a.every((item, index) => b[index] === item);
}

export function shallowEqual(a: { [k: string]: any }, b: { [k: string]: any }) {
  let aKeys = Object.keys(a);
  let bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) return false;

  for (let key of aKeys) {
    if (aKeys[key] !== bKeys[key]) {
      return false;
    }
  }

  return true;
}

export class MutableSelectable<T> {
  private listeners = new Set<Callback<T>>();
  queue: (() => any)[] = [];
  pending: Promise<any>[] = [];
  suspended?: Promise<any>;
  state: T;
  version: number = 0;
  log: boolean;
  constructor(initialState: T, log: boolean = typeof window !== "undefined") {
    this.state = initialState;
    this.log = log;
  }
  runUpdate() {
    // let start = Date.now();
    this.listeners.forEach((listener) => {
      listener(this.state);
    });
    // if (this.log) {
    //   // console.log(`Update took ${Date.now() - start}ms`)
    // }
  }
  subscribe(callback: Callback<T>) {
    this.listeners.add(callback);
    return () => {
      this.listeners.delete(callback);
    };
  }
  suspend(promise: Promise<any>) {
    this.pending.push(promise);
    this.unsuspend();
    return promise;
  }
  unsuspend() {
    if (this.suspended) {
      return this.suspended;
    }
    const onceUnsuspended = async () => {
      while (this.pending.length) {
        let prom = this.pending.pop();
        await prom;
      }
      this.queue.forEach((queue) => {
        queue();
      });
      this.queue = [];
      this.suspended = undefined;
      console.log("Queue emptied");
    };
    this.suspended = onceUnsuspended();
    return this.suspended;
  }
  use<T extends SelectablePlugin>(...plugins: T[]) {
    plugins.forEach((plugin) => {
      if (plugin) plugin(this);
    });
    return this;
  }
  set(updater: Callback<T>) {
    let doUpdate = () => {
      updater(this.state);
      this.version++;
      this.runUpdate();
    };
    if (this.pending.length) {
      console.log("queuing");
      this.queue.push(doUpdate);
    } else {
      doUpdate();
    }
  }
  select<V>(
    selector: Callback<T, V>,
    onChange: Callback<V>,
    equalityCheck = strictEqual
  ) {
    let prev: V;
    try {
      prev = selector(this.state);
    } catch (e) {
      // @ts-ignore
      prev = undefined;
    }
    onChange(prev);
    return this.subscribe((state) => {
      let current: V;
      try {
        current = selector(state);
      } catch (e) {
        // @ts-ignore
        current = undefined;
      }
      if (!equalityCheck(prev, current)) {
        prev = current;
        onChange(current);
      }
    });
  }
  getSnapshot() {
    return this.version;
  }
  clone() {
    return new Selectable(this.state);
  }
  destroy() {
    this.listeners.clear();
  }
}

function logSource(depth) {
  try {
    throw new Error();
  } catch (e) {
    // @ts-ignore
    let lines = e.stack.split("\n");
    lines.shift();
    // console.log(...lines[depth].split(' ').filter(Boolean))
  }
}

export class Selectable<T> extends MutableSelectable<T> {
  set(updater: Callback<T>) {
    logSource(2);
    const doUpdate = () => {
      let change = produce(this.state, updater);
      if (change !== this.state) {
        this.state = change;
        this.version++;
        this.runUpdate();
      }
    };
    if (this.pending.length) {
      console.log("queuing");
      this.queue.push(doUpdate);
    } else {
      doUpdate();
    }
  }
}

export function useSelectable<S, T>(
  store: Selectable<S> | undefined,
  selector: (arg: S) => T,
  equalityCheck = strictEqual
): T | undefined {
  let [state, set] = useState(store ? selector(store.state) : undefined);

  /* eslint-disable */
  useLayoutEffect(() => {
    let current = store ? selector(store.state) : undefined;
    if (!equalityCheck(current, state)) set(current);
    if (store) {
      return store.select(selector, set, equalityCheck);
    }
  });
  /* eslint-enable */

  return state;
}

export function useSelectableSuspense<V, K extends Selectable<T>, T>(
  store: K,
  selector: Callback<T, V>
) {
  let value;
  try {
    value = selector(store.state);
  } catch (e) {}
  if (value === undefined) {
    throw new Promise<void>((resolve) => {
      let off = store.select(selector, (value) => {
        if (value !== undefined) {
          off();
          resolve();
        }
      });
    });
  }
}
