import { IonProgressBar, IonText } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { store } from '../../api/store'
import { IntroLogo } from '../../assets/svg/introLogos'
import { useSelectable } from '../../utils/state/selectable'
import './loader.scss'

const SPLASHES = [
  'Loading electorates',
  'Loading election results',
  'Loading map data',
]

export function Loader() {
  let loading = useSelectable(store, (state) =>
    state.view
      ? state.loadingViews[state.view]
      : state.loadingProgress ?? state.loadingProgress
  )
  let [index, set] = useState(0)

  useEffect(() => {
    let int = setInterval(() => {
      set((i) => (i + 1) % SPLASHES.length)
    }, 8000)
    return () => clearInterval(int)
  }, [])

  return (
    <div className="loader">
      <div className="content">
        <div className="logo">
          <IntroLogo />
        </div>

        <IonText className="progress">
          {SPLASHES.map((splash, k) => {
            let isNext =
              (index === SPLASHES.length - 1 && k === 0) || index + 1 === k
            let isCurrent = k === index
            let styles: any = {}

            if (isCurrent) {
              styles.transform = 'translateY(0)'
              styles.opacity = 1
              styles.transition = 'transform 0.3s 0.15s, opacity 0.3s 0.15s'
            } else if (isNext) {
              styles.transform = 'translateY(50%)'
              styles.opacity = 0
              styles.transition = ''
            } else {
              styles.transform = 'translateY(-50%)'
              styles.opacity = 0
              styles.transition = 'transform 0.3s, opacity 0.3s'
            }

            return (
              <span aria-hidden={!isCurrent} key={k} style={styles}>
                {splash}
              </span>
            )
          })}
        </IonText>
        <IonProgressBar value={loading} />
      </div>

      <IonText className="copyright">
        <svg
          width="29"
          height="32"
          viewBox="0 0 29 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5495 0.609525H13.9494V6.70478H14.5495V0.609525Z"
            fill="#9F9D97"
          />
          <path
            d="M10.7049 2.59049L9.41944 1.6762H3.32043L4.60593 2.59049H10.7049Z"
            fill="#9F9D97"
          />
          <path
            d="M23.893 2.59049L25.1785 1.6762H19.0795L17.794 2.59049H23.893Z"
            fill="#9F9D97"
          />
          <path
            d="M19.2419 3.96192H21.9659L23.2514 3.04763H17.1524L15.5999 4.15087V3.59315L18.9374 1.21905H25.8209L27.5354 0H18.5594L15.5999 2.10591V0.609525H14.9999V6.70478H15.5999V4.64763C16.4414 4.73601 17.2619 4.89601 18.0344 5.19316L14.2499 13.4355L10.4654 5.19163C11.2364 4.89601 12.0569 4.73449 12.8984 4.64611V6.70478H13.4984V0.609525H12.8984V2.10591L9.94035 0H0.964329L2.67883 1.21905H9.56235L12.8999 3.59315V4.15087L11.3489 3.04763H5.24834L6.53384 3.96192H9.25785C3.90884 5.99468 0.0838262 11.1985 0.00132599 17.3227C-0.105174 25.277 6.21734 31.8888 14.0459 31.9986C22.0094 32.1113 28.4999 25.5879 28.4999 17.5239C28.4999 11.3128 24.6494 6.01754 19.2419 3.96192ZM17.9444 8.99202L20.4614 14.4762H15.4259L17.9444 8.99202ZM16.7099 22.4001H11.7899L14.2499 17.0408L16.7099 22.4001ZM10.5554 8.99202L13.0724 14.4762H8.03835L10.5554 8.99202ZM1.49983 17.5239C1.49983 12.2652 4.60334 7.73031 9.04635 5.70363L9.72735 7.18935L2.58583 22.7475C1.88983 21.1475 1.49983 19.3814 1.49983 17.5239ZM3.46934 24.4283L7.33785 16H13.0724L7.37085 28.4206C5.80184 27.3951 4.46834 26.0298 3.46934 24.4283ZM14.2499 30.4763C12.2549 30.4763 10.3649 30.0069 8.68035 29.1734L11.0909 23.9239H17.4089L19.8179 29.1734C18.1349 30.0069 16.2449 30.4763 14.2499 30.4763ZM21.1289 28.4206L15.4274 16H21.1619L25.0304 24.4283C24.0314 26.0298 22.6979 27.3951 21.1289 28.4206ZM18.7724 7.18935L19.4549 5.70363C23.8964 7.73031 26.9999 12.2652 26.9999 17.5239C26.9999 19.3814 26.6099 21.1475 25.9139 22.7475L18.7724 7.18935Z"
            fill="#9F9D97"
          />
        </svg>
        <br />© 2020 Australian Automobile Association
      </IonText>
    </div>
  )
}
