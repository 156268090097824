import React from 'react'

export const IconFuelCost = () => {
  return (
    <>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.76111 2.35L8.76667 2.34444L6.7 0.277778L6.11111 0.866667L7.28333 2.03889C6.76111 2.23889 6.38889 2.73889 6.38889 3.33333C6.38889 3.51572 6.42481 3.69633 6.49461 3.86484C6.56441 4.03335 6.66672 4.18646 6.79569 4.31543C7.05615 4.57589 7.40942 4.72222 7.77778 4.72222C7.97778 4.72222 8.16111 4.67778 8.33333 4.60556V8.61111C8.33333 8.91667 8.08333 9.16667 7.77778 9.16667C7.47222 9.16667 7.22222 8.91667 7.22222 8.61111V6.11111C7.22222 5.5 6.72222 5 6.11111 5H5.55556V1.11111C5.55556 0.5 5.05556 0 4.44444 0H1.11111C0.5 0 0 0.5 0 1.11111V10H5.55556V5.83333H6.38889V8.61111C6.38889 8.97947 6.53522 9.33274 6.79569 9.5932C7.05615 9.85367 7.40942 10 7.77778 10C8.14613 10 8.4994 9.85367 8.75987 9.5932C9.02034 9.33274 9.16667 8.97947 9.16667 8.61111V3.33333C9.16667 2.95 9.01111 2.6 8.76111 2.35ZM4.44444 3.88889H1.11111V1.11111H4.44444V3.88889ZM7.77778 3.88889C7.47222 3.88889 7.22222 3.63889 7.22222 3.33333C7.22222 3.02778 7.47222 2.77778 7.77778 2.77778C8.08333 2.77778 8.33333 3.02778 8.33333 3.33333C8.33333 3.63889 8.08333 3.88889 7.77778 3.88889Z" fill="white"/>
      </svg>
    </>
  )
}