import React from 'react'

export const IconPendulum = () => {
  return (
    <>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 3.2C8 2.0799 8 1.51984 7.78201 1.09202C7.59027 0.715695 7.28431 0.409734 6.90798 0.217987C6.48016 0 5.9201 0 4.8 0H3.2C2.0799 0 1.51984 0 1.09202 0.217987C0.715695 0.409734 0.409734 0.715695 0.217987 1.09202C0 1.51984 0 2.0799 0 3.2V6C0 11.5228 4.47715 16 10 16C15.5228 16 20 11.5228 20 6V3.2C20 2.0799 20 1.51984 19.782 1.09202C19.5903 0.715695 19.2843 0.409734 18.908 0.217987C18.4802 0 17.9201 0 16.8 0H15.2C14.0799 0 13.5198 0 13.092 0.217987C12.7157 0.409734 12.4097 0.715695 12.218 1.09202C12 1.51984 12 2.0799 12 3.2V6C12 7.10457 11.1046 8 10 8C8.89543 8 8 7.10457 8 6V3.2Z" fill="black"/>
      </svg>
    </>
  )
}