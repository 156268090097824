import React, { useMemo, useState } from 'react'
import { IonText } from '@ionic/react'
import { Rotate } from '../assets/svg/rotate'
import './RotateOverlay.scss'

const RotateOverlay = () => {
  const [rotation, setRotation] = useState(true)

  const checkOrientationChange = () => {
    let screenOrientation = window.orientation;
    switch(screenOrientation){
      case 0:
        setRotation(true)
        break;
      case 90:
        setRotation(false)
        break;
      case 180:
        setRotation(true)
        break;
      case 270:
        setRotation(false)
        break;
      case -90:
        setRotation(false)
        break;
      case -180:
        setRotation(true)
        break;
      default:
        setRotation(true)
    }
  }
  
  useMemo(() => {
    checkOrientationChange()
    window.addEventListener('orientationchange', checkOrientationChange)
  }, [])
  

  return (
    <>
      { !rotation && (
        <div className="rotate-overlay">
          <Rotate />
          <IonText>
            This app is best viewed in Portrait mode.<br />
            <strong>Please rotate your device.</strong>
          </IonText>
        </div>
      )}
    </>
  )
}

export default RotateOverlay