import React from 'react'

export const IconCenterMap = () => {
  return (
    <>
      <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="14" r="9" stroke="white" strokeWidth="2"/>
        <circle cx="13" cy="14" r="4" fill="white"/>
        <rect x="12" width="2" height="5" fill="white"/>
        <rect x="12" y="23" width="2" height="5" fill="white"/>
        <rect y="13" width="4" height="2" fill="white"/>
        <rect x="22" y="13" width="4" height="2" fill="white"/>
      </svg>
    </>
  )
}
