const IS_CLIENT = typeof window !== 'undefined'

if (IS_CLIENT) {
  /* Polyfill lets you iterate ReadableStreams */
  if (!ReadableStream.prototype[Symbol.asyncIterator]) {
    ReadableStream.prototype[Symbol.asyncIterator] = async function* () {
      const reader = this.getReader()
      while (1) {
        const chunk = await reader.read()
        if (chunk.done) return chunk.value
        yield chunk.value
      }
    }
  }
}

export async function fetchProgress(
  fetch: ReturnType<typeof globalThis.Fetch>,
  onUpdate: (recieved: number, contentLength: number) => any,
  format: (data: Uint8Array) => any = (data) => {
    let result = new TextDecoder('utf-8').decode(data)
    return JSON.parse(result)
  }
) {
  let response = await fetch

  const contentLength = +response.headers.get('Content-Length') || 0

  // Step 3: read the data
  let receivedLength = 0 // received that many bytes at the moment
  let chunks = [] // array of received binary chunks (comprises the body)

  for await (let chunk of response.body) {
    chunks.push(chunk)
    receivedLength += chunk.length
    onUpdate(receivedLength, contentLength)
  }

  // Step 4: concatenate chunks into single Uint8Array
  let chunksAll = new Uint8Array(receivedLength) // (4.1)
  let position = 0
  for (let chunk of chunks) {
    chunksAll.set(chunk, position) // (4.2)
    position += chunk.length
  }

  return format(chunksAll)
}
