import { Selectable } from '../utils/state/selectable'
import { LocalStoragePlugin } from './selectable-plugin-localstorage'

export const preferences = new Selectable({
  useAirtable: false,
  ignoreCache: false,
})

if (typeof window !== 'undefined') {
  preferences.use(
    LocalStoragePlugin({
      key: 'ADMIN_PREFERENCES',
      timeout: 300,
      localStorage: true,
    })
  )
}
