import React, { useEffect, useState } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { IonApp } from '@ionic/react'
import { Onboarding } from './pages/onboarding/Onboarding'
import { CurrentView } from './pages/tabs/CurrentView'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/fonts.scss'
import './theme/theme-blank.scss'
import './theme/theme-parties.scss'

import { store, load } from './api/store'
import { Loader } from './components/loader/loader'
import { Admin } from './pages/admin/admin'
import ReactGA from 'react-ga'
import RotateOverlay from './components/RotateOverlay'
import { Data } from './pages/data/Data'

load().then(() => {
  console.log('App is loaded')
})

/* Update theme colours */
if (typeof document !== 'undefined') {
  let style = document.createElement('style')
  document.head.appendChild(style)
  /* Update party colours */
  store.select(
    (state) => state.views[state.view].parties,
    (parties) => {
      if (!parties) return
      let styles = []
      parties.forEach((party) => {
        document.documentElement.style.setProperty(
          `--party-${party.Party.toLowerCase()}`,
          party.Colour
        )
        styles.push(`.--party-${party.Party.toLowerCase()} {
          color: ${party.Colour};
        }`)
      })

      style.innerHTML = styles.join('')
    }
  )

  globalThis.store = store
  // store.subscribe((state) => console.log(state))
}

ReactGA.initialize('UA-24222730-17')
ReactGA.pageview(window.location.pathname + window.location.search)

const App: React.FC = () => {
  let [restoringCache, setRestoringCache] = useState(!!store.suspended)

  useEffect(() => {
    if (store.pending) {
      console.log('Restoring cache')
      let cancelled = false
      Promise.resolve(store.suspended).then(() => {
        console.log('Store has finished pending', store.state.view)
        if (cancelled) return
        setRestoringCache(false)
      })
      return () => {
        cancelled = true
      }
    }
  }, [])

  return (
    <IonApp>
      {restoringCache && <Loader />}
      {!restoringCache && (
        <>
          <BrowserRouter>
            <Switch>
              <Route path="/onboarding" render={() => <Onboarding />} />
              <Route path="/admin" component={Admin} />
              <Route
                path="/\.netlify"
                render={() => <Redirect to="/admin" />}
              />
              <Route path="/format-data" component={Data} />
              <Route path="/" render={() => <CurrentView />} />
            </Switch>
          </BrowserRouter>
        </>
      )}
      <RotateOverlay />
    </IonApp>
  )
}

export default App
