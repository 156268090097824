import React, { useEffect, useMemo } from "react";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonPage,
} from "@ionic/react";
import { PendulumElement } from "../../../assets/svg/pendulumElement";
import PendulumSlider from "../../../components/PendulumSlider";
import "./Pendulum.scss";
import { useView } from "../../../api/store";
import { IconDownloadPDF } from "../../../assets/svg/iconDownloadPDF";

// Test

const PARTY_LABEL_MAP = {
  LNP: {
    QLD: "LNP",
    WA: "NP/LP",
    SA: "LP",
    NT: "CLP",
    default: "Coalition",
  },
  ALP: {
    default: "ALP",
  },
};

const PARTY_LABEL_COLOR = {
  LNP: {
    WA: "--party-np",
    NT: "--party-clp",
    default: "--party-lnp",
  },
  ALP: {
    default: "--party-alp",
  },
};

const Pendulum = () => {
  let [viewId] = useView((view) => view.id);
  let [incumbents] = useView(
    (view) => view.mode === "default" && view.incumbents
  );
  let [currentPDF] = useView((view) => view.meta[0]["Pendulum download PDF"]);

  let seats = useMemo(() => {
    let results: {
      LNP: typeof incumbents;
      ALP: typeof incumbents;
      OTHER: typeof incumbents;
      GOV: typeof incumbents;
      NOT_GOV: typeof incumbents;
      FULL: typeof incumbents;
    } = {
      LNP: [],
      ALP: [],
      OTHER: [],
      GOV: [],
      NOT_GOV: [],
      FULL: [],
    };
    if (!incumbents.length) return results;

    for (let seat of incumbents) {
      let party = seat["Elected Party (TCP) [Incumbent]"];
      switch (party) {
        case "LNP":
        case "LP":
        case "NP":
        case "CLP":
        case "LNP (NP)":
        case "LIB":
        case "NAT":
          results.LNP.push(seat);
          break;
        case "ALP":
          results[party].push(seat);
          break;
        default:
          results.OTHER.push(seat);
      }
    }

    if (results.ALP.length > results.LNP.length) {
      results.GOV = results.ALP;
      results.NOT_GOV = [...results.LNP, ...results.OTHER];
    } else {
      results.GOV = results.LNP;
      results.NOT_GOV = [...results.ALP, ...results.OTHER];
    }

    results.GOV.sort(
      (a, b) => b["TCP Margin [Incumbent]"] - a["TCP Margin [Incumbent]"]
    );
    results.NOT_GOV.sort(
      (a, b) => a["TCP Margin [Incumbent]"] - b["TCP Margin [Incumbent]"]
    );
    results.FULL = [...results.GOV, ...results.NOT_GOV];

    return results;
  }, [incumbents]);

  useEffect(() => {
    if (viewId === "TAS") {
      window.location.pathname = "/map";
    }
  }, [viewId]);

  return viewId === "TAS" ? (
    <></>
  ) : (
    <IonPage className="pendulumPage">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Pendulum</IonTitle>
          {currentPDF && (
            <a
              className="downloadPDF"
              href={currentPDF}
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              <IconDownloadPDF />
            </a>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent scrollX={false}>
        <IonRow className="counters">
          {seats.ALP.length > seats.LNP.length ? (
            <>
              <IonCol size="4">
                <div className="counter">
                  <label>
                    {PARTY_LABEL_MAP.ALP[viewId] || PARTY_LABEL_MAP.ALP.default}
                  </label>
                  <span className="--party-alp">{seats.ALP.length}</span>
                </div>
              </IonCol>
              <IonCol size="4">
                <div className="counter">
                  <label>Other</label>
                  <span className="--party-oth">{seats.OTHER.length}</span>
                </div>
              </IonCol>
              <IonCol size="4">
                <div className="counter">
                  <label>
                    {PARTY_LABEL_MAP.LNP[viewId] || PARTY_LABEL_MAP.LNP.default}
                  </label>
                  <span
                    className={`${
                      PARTY_LABEL_COLOR.LNP[viewId] ||
                      PARTY_LABEL_COLOR.LNP.default
                    }`}
                  >
                    {seats.LNP.length}
                  </span>
                </div>
              </IonCol>
            </>
          ) : (
            <>
              <IonCol size="4">
                <div className="counter">
                  <label>
                    {PARTY_LABEL_MAP.LNP[viewId] || PARTY_LABEL_MAP.LNP.default}
                  </label>
                  <span
                    className={`${
                      PARTY_LABEL_COLOR.LNP[viewId] ||
                      PARTY_LABEL_COLOR.LNP.default
                    }`}
                  >
                    {seats.LNP.length}
                  </span>
                </div>
              </IonCol>
              <IonCol size="4">
                <div className="counter">
                  <label>Other</label>
                  <span className="--party-oth">{seats.OTHER.length}</span>
                </div>
              </IonCol>
              <IonCol size="4">
                <div className="counter">
                  <label>
                    {PARTY_LABEL_MAP.ALP[viewId] || PARTY_LABEL_MAP.ALP.default}
                  </label>
                  <span className="--party-alp">{seats.ALP.length}</span>
                </div>
              </IonCol>
            </>
          )}
        </IonRow>
        <IonRow className="pendulum">
          <IonCol size="12">
            <div className="pendulumArea">
              <PendulumElement seats={seats} />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="slider">
          <IonCol size="12" style={{ padding: 0 }}>
            <PendulumSlider seats={seats} key={viewId} />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Pendulum;
