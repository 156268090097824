import React from 'react'
import {
  IonTitle,
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonText,
  IonButton
} from '@ionic/react'
import './DrawerModal.scss'
import { IconBack } from '../assets/svg/back'
import { IconClose } from '../assets/svg/close'

const DrawerModal = (props) => {
  const createMarkup = () => {
    return { __html: props.data.Content }
  }

  return (
    <>
      <IonModal isOpen={props.show} cssClass="drawer-modal">
        <IonHeader>
          <IonToolbar>
            <div className="icon-back" onClick={props.close}>
              <IconBack />
            </div>
            <IonTitle>{props.data.Page}</IonTitle>
            <div className="icon-close" onClick={props.close}>
              <IconClose />
            </div>
          </IonToolbar>
        </IonHeader>

        <IonContent className="modal-content">
          <div dangerouslySetInnerHTML={createMarkup()} />
          { props.data.Page === "RACQ State Priorities" && (
            <div className="modal-tab logos">
              <IonText>For media queries about these projects, contact the RACQ media team.</IonText>
              <IonButton
                expand="block"
                href="https://www.racq.com.au/Live/Articles/contact-us"
                target="_blank"
              >
                Contact RACQ
              </IonButton>
            </div>
          )}
        </IonContent>
      </IonModal>
    </>
  )
}

export default DrawerModal
