import React from 'react'

export const IconResetZoom = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 8H13.3333V9.33333H9.33333V13.3333H8V8Z" fill="white"/>
        <path d="M8 24V18.6667H9.33333V22.6667H13.3333V24H8Z" fill="white"/>
        <path d="M24 8V13.3333H22.6667V9.33333H18.6667V8H24Z" fill="white"/>
        <path d="M24 24H18.6667V22.6667H22.6667V18.6667H24V24Z" fill="white"/>
      </svg>
    </>
  )
}

