export const API_KEY = "key5iuVLFurhXW7kB";

export const ELECTORATES = [
  { id: "QLD", base: "appzSJFdT5ywlD3kQ", mode: "regular" },
  { id: "FED", base: "appmVhqIT9slf3BPp", mode: "regular" },
  { id: "WA", base: "app9rr3guBYetugtd", mode: "regular" },
  { id: "TAS", base: "appuYbaFkLgb8Ecru", mode: "count" },
  { id: "SA", base: "appqC8POLSpMUNffF", mode: "regular" },
  { id: "NT", base: "appaIiGM92oIUwuff", mode: "regular" },
  { id: "ACT", base: "appjg2PoTSYbrHpa5", mode: "count" },
  { id: "NSW", base: "apptDYihUYzosRV0p", mode: "regular" },
  { id: "VIC", base: "apptdqdRDZtYlmOEo", mode: "regular" },
];
export const TABLES = {
  results: "Results",
  incumbents: "Incumbents",
  priorities: "Priorities",
  candidates: "Candidates",
  pages: "Menu Pages",
  parties: "Party Attributes",
  meta: "Meta",
  countTotals: "Party Totals",
  strings: "Strings",
};
