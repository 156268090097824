import React from 'react'
import "./InsightIcon.scss"
import { IconFuelCost } from "../assets/svg/IconFuelCost"
import { IconTrafficCongestion } from "../assets/svg/IconTrafficCongestion"

const InsightIcon = (props) => {
  return (
    <>
      <div className="insight-icon">
        { props.type === 'fuel' && (
          <IconFuelCost />
        )}
        { props.type === 'traffic' && (
          <IconTrafficCongestion />
        )}
      </div>
    </>
  )
}

export default InsightIcon