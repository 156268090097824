import React, { useEffect, useRef, useState } from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react'
import './admin.scss'
import Hamburger from '../../components/Hamburger'
import Drawer from '../../components/Drawer'
import { useSelectable } from '../../utils/state/selectable'
import { preferences } from '../../api/preferences'
import { store } from '../../api/store'
import { useHistory, useLocation } from 'react-router'
import { IconDirectory } from '../../assets/svg/directory'
import { IconMap } from '../../assets/svg/map'
import { IconPendulum } from '../../assets/svg/pendulum'

const USER = 'aaa_admin'
const PASSWORD = window.location.hostname + ' admin_password'

let i = 0

export function BuildImage() {
  let ref = useRef<HTMLDivElement>()

  useEffect(() => {
    let createImage = () => {
      let img = document.createElement('img')
      img.style.position = 'absolute'
      img.onload = () => {
        for (let child of ref.current.children) {
          child.remove()
        }
        ref.current.append(img)
      }
      img.src =
        'https://api.netlify.com/api/v1/badges/c34eb712-0817-40b1-9d74-5f4c71081eb5/deploy-status?v=' +
        i++
    }
    let tm = setInterval(createImage, 6000)
    createImage()
    return () => clearInterval(tm)
  }, [])

  return <div ref={ref} style={{ height: '20px', width: '136px' }}></div>
}

export function Admin() {
  let prefs = useSelectable(preferences, (v) => v)
  let history = useHistory()
  let location = useLocation()
  let [authed, setAuthed] = useState(() => {
    let token = parse(localStorage.getItem('ADMIN_LOGIN'))
    return token && token.user === USER && token.password === PASSWORD
  })
  let [loading, setLoading] = useState<false | Promise<any>>(false)
  let authRef = useRef(authed)

  useEffect(() => {
    authRef.current = authed
  })

  useEffect(() => {
    if (authRef.current) return // eslint-disable-line
    let user = window.prompt('User')
    if (!user) return
    let password = window.prompt('Password')
    if (!password) return
    if (password === PASSWORD && user === USER) {
      setAuthed(true)
      localStorage.setItem('ADMIN_LOGIN', JSON.stringify({ user, password }))
    }
  }, [])

  useEffect(() => {
    if (!loading) return
    let cancelled = false
    loading.then(() => {
      setTimeout(() => {
        if (cancelled) return
        setLoading(false)
      }, 6000)
    })
    return () => {
      cancelled = true
    }
  }, [loading])

  if (!authed) {
    return null
  }

  function navigateTo(url: string) {
    return () => {
      history.push(url)
    }
  }

  return (
    <>
      <Hamburger />
      <IonPage className="admin">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Admin</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItemDivider>Preferences</IonItemDivider>
                <IonItem>
                  <IonLabel>
                    Always use Airtable data
                    <div style={{ opacity: 0.5 }}>
                      {prefs.useAirtable
                        ? `Will download from Airtable on every reload`
                        : `Will use data from ${
                            prefs.ignoreCache ? 'JSON' : 'the local cache'
                          }`}
                    </div>
                  </IonLabel>
                  <IonToggle
                    checked={prefs.useAirtable}
                    onIonChange={(e) =>
                      preferences.set((store) => {
                        store.useAirtable = e.detail.checked
                      })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>
                    Ignore the local cache
                    <div style={{ opacity: 0.5 }}>
                      {prefs.ignoreCache
                        ? `Will download from ${
                            prefs.useAirtable ? 'Airtable' : 'JSON'
                          } on every reload`
                        : 'Will use data that has already been downloaded'}
                    </div>
                  </IonLabel>
                  <IonToggle
                    disabled={prefs.useAirtable}
                    checked={prefs.useAirtable || prefs.ignoreCache}
                    onIonChange={(e) =>
                      preferences.set((store) => {
                        store.ignoreCache = e.detail.checked
                      })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>
                    Clear local cache
                    <div style={{ opacity: 0.5 }}>Removes all local data</div>
                    <IonButton
                      onClick={() => {
                        store.set((state) => {
                          state.views = {}
                        })
                        setTimeout(() => {
                          window.location.href = '/map'
                        }, 1000)
                      }}
                      size="small"
                      style={{
                        marginTop: '10px',
                        fontSize: '12px',
                        '--padding-start': '20px',
                        '--padding-end': '20px',
                      }}
                    >
                      Clear cache
                    </IonButton>
                  </IonLabel>
                </IonItem>

                <IonItemDivider style={{ marginTop: '30px' }}>
                  Vercel Deployments
                </IonItemDivider>
                {/* <IonItem>
                  <IonLabel>
                    <div style={{ whiteSpace: 'normal', paddingRight: '4px' }}>
                      Netlify status
                      <div style={{ opacity: 0.5 }}>
                        Probably don't trigger a new build when already building
                      </div>
                    </div>
                  </IonLabel>
                  <BuildImage />
                </IonItem> */}
                <IonItem>
                  <IonLabel>
                    <div style={{ whiteSpace: 'normal', paddingRight: '4px' }}>
                      Rebuilds your site with fresh data
                      <div style={{ opacity: 0.5 }}>
                        Rebuild the site with new content from Airtable
                      </div>
                    </div>
                    <IonButton
                      size="large"
                      style={{
                        marginTop: '10px',
                        fontSize: '12px',
                        '--padding-start': '14px',
                        '--padding-end': '14px',
                      }}
                      disabled={!!loading}
                      onClick={(e) => {
                        setLoading(
                          fetch(
                            'https://api.vercel.com/v1/integrations/deploy/Qmba2szEDazPJWv9uHbcEZG4wyWXtsuc7tkfkaWk1WxfnH/vuDf6A6ISY'
                          )
                        )
                      }}
                    >
                      {loading ? 'Requesting deployment' : 'Trigger deployment'}
                    </IonButton>
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonToolbar>
          <IonSegment
            mode="md"
            className="main-nav"
            value={location.pathname.split('/')[1]}
          >
            <IonSegmentButton value="map" onClick={navigateTo('/map')}>
              <IconMap />
              <IonLabel>Map</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="directory"
              onClick={navigateTo('/directory')}
            >
              <IconDirectory />
              <IonLabel>Directory</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="pendulum"
              onClick={navigateTo('/pendulum')}
            >
              <IconPendulum />
              <IonLabel>Pendulum</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonPage>
      <Drawer />
    </>
  )
}

function parse(str) {
  try {
    return JSON.parse(str)
  } catch (e) {}
}
