import React from 'react'

export const IconDown = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 12L16 20L24 12" stroke="#373530" strokeWidth="2"/>
      </svg>
    </>
  )
}

