import React, { useEffect, useMemo, useRef } from "react";
import { IonRow, IonCol, IonText, IonLabel } from "@ionic/react";
import { motion } from "framer-motion";
import "./PendulumSlider.scss";
import { useMeasure } from "../utils/hooks/use-measure";
import { useView } from "../api/store";

const PendulumSlider = ({ seats }) => {
  let percentagRef = useRef<HTMLIonTextElement>();
  let animationRef = useRef<number>();
  let [listItemRef, { width }] = useMeasure();
  let positionRef = useRef(seats.GOV.length * -18 + window.innerWidth / 2);
  let lastActiveSeatIndex = useRef<number>(0);
  let seatElementsRef = useRef<HTMLElement[]>([]);
  let majorityElement = useRef<HTMLElement>(null);
  let [viewId] = useView((view) => view.id);
  console.log(majorityElement);
  useEffect(() => {
    seatElementsRef.current = Array.from(
      document.querySelectorAll(".--seat")
    ).map((el) => el.parentElement);
    majorityElement.current = document.querySelector(".majority-index");
  }, []);

  // @ts-ignore
  let margin = 1;
  width = width + margin;

  let swingDirectionLeftRef = useRef<any>(
    '<span className="sd-zero">&larr; Swing against</span>'
  );
  let swingDirectionRightRef = useRef<any>(
    '<span className="sd-zero">Swing to govt. &rarr;</span>'
  );

  let swingStatus = useMemo(() => {
    let status = {
      govMostlySafe: null,
      govSafe: null,
      govVerySafe: null,
      oppMostlySafe: null,
      oppSafe: null,
      oppVerySafe: null,
    };
    //Work out margins
    let prevMarginGov = 0;
    seats.GOV.slice()
      .reverse()
      .forEach((record, key) => {
        let currentMargin = record["TCP Margin [Incumbent]"];
        if (
          prevMarginGov < 5 &&
          currentMargin >= 5 &&
          status.govMostlySafe === null
        ) {
          status.govMostlySafe = seats.GOV.length - key;
        } else if (
          prevMarginGov < 10 &&
          currentMargin >= 10 &&
          status.govSafe === null
        ) {
          status.govSafe = seats.GOV.length - key;
        } else if (
          prevMarginGov < 15 &&
          currentMargin >= 15 &&
          status.govVerySafe === null
        ) {
          status.govVerySafe = seats.GOV.length - key;
        }
        prevMarginGov = currentMargin;
      });

    let prevMarginOpp = 0;
    seats.NOT_GOV.forEach((record, key) => {
      let currentMargin = record["TCP Margin [Incumbent]"];

      if (
        prevMarginOpp < 5 &&
        currentMargin >= 5 &&
        status.oppMostlySafe === null
      ) {
        status.oppMostlySafe = key + seats.GOV.length;
      } else if (
        prevMarginOpp < 10 &&
        currentMargin >= 10 &&
        status.oppSafe === null
      ) {
        status.oppSafe = key + seats.GOV.length;
      } else if (
        prevMarginOpp < 15 &&
        currentMargin >= 15 &&
        status.oppVerySafe === null
      ) {
        status.oppVerySafe = key + seats.GOV.length;
      }
      prevMarginOpp = currentMargin;
    });

    return status;
  }, [seats]);

  let majorityIndex = useMemo(
    () => seats.GOV.length - Math.floor(seats.FULL.length / 2),
    [seats]
  );

  const fadeColour = (margin) => {
    let fade = " -marginal";
    if (parseFloat(margin) >= 15) {
      fade = " -very-safe";
    } else if (parseFloat(margin) >= 10) {
      fade = " -safe";
    } else if (parseFloat(margin) >= 5) {
      fade = " -mostly-safe";
    }
    return fade;
  };

  function updatePercentage(latest) {
    positionRef.current = latest.x;
    if (animationRef.current) return;

    animationRef.current = requestAnimationFrame(() => {
      let maxLeft = seats.NOT_GOV.length * -width;
      let left = positionRef.current;

      /* Should be 0 at the far left of the slider and *big number* at the end of the slider */
      let indexPosition = Math.min(
        seats.FULL.length * width,
        Math.max(0, seats.FULL.length * width - (left - maxLeft))
      );

      let index = Math.ceil(indexPosition / width);

      if (left < 0) {
        index -= 1;
      }

      lastActiveSeatIndex.current = index;

      let activeEls =
        index < seats.GOV.length
          ? seatElementsRef.current.slice(index, seats.GOV.length)
          : seatElementsRef.current.slice(seats.GOV.length, index + 1);

      if (Math.abs(left) < width) {
        percentagRef.current.innerText = "0%";
        swingDirectionLeftRef.current.innerHTML =
          '<span class="sd-zero">&larr; Swing against</span>';
        swingDirectionRightRef.current.innerHTML =
          '<span class="sd-zero">Swing to govt. &rarr;</span>';
        animationRef.current = undefined;
        seatElementsRef.current.forEach((el) => {
          //@ts-ignore
          el.style = {};
        });
        return;
      }

      seatElementsRef.current.forEach((el) => {
        //@ts-ignore
        el.style = {};
      });

      activeEls.forEach((el) => {
        el.style.transform = "scale(1.1)";
      });

      if (majorityElement.current)
        majorityElement.current.style.transform =
          seats.GOV.length - index + 1 > majorityIndex ? "scale(1.1)" : "";

      let item = seats.FULL[index] || seats.FULL[seats.FULL.length - 1];

      swingDirectionLeftRef.current.innerHTML =
        '<span class="sd-left">&larr; Swing against</span>';
      swingDirectionRightRef.current.innerHTML =
        '<span class="sd-left">Swing to govt. &rarr;</span>';
      if (left < 0) {
        swingDirectionLeftRef.current.innerHTML =
          '<span class="sd-right">&larr; Swing against</span>';
        swingDirectionRightRef.current.innerHTML =
          '<span class="sd-right">Swing to govt. &rarr;</span>';
      }

      percentagRef.current.innerText =
        Number(item["TCP Margin [Incumbent]"]).toFixed(2) + "%";

      animationRef.current = undefined;
    });
  }

  return (
    <>
      {Object.keys(seats).length !== 0 && (
        <>
          <IonRow className="sliderHeader">
            <IonCol size="4">
              <IonText ref={swingDirectionLeftRef} className={"label"}>
                <span className="sd-zero">&larr; Swing against</span>
              </IonText>
            </IonCol>
            <IonCol size="4">
              <IonText className="percentage" ref={percentagRef}>
                0%
              </IonText>
            </IonCol>
            <IonCol size="4">
              <IonText ref={swingDirectionRightRef} className={"label right"}>
                <span className="sd-zero">Swing to govt. &rarr;</span>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="sliderBody">
            <IonCol size="12">
              <motion.div
                className="pendulumScroller"
                dragDirectionLock
                drag="x"
                initial={{
                  left: positionRef.current + "px",
                }}
                onUpdate={updatePercentage}
                dragElastic={0.2}
                dragConstraints={{
                  left: seats.NOT_GOV.length * -width,
                  right: seats.GOV.length * width,
                }}
                dragTransition={{
                  power: 0.2,
                  modifyTarget: (target) => Math.round(target / width) * width,
                  timeConstant: 100,
                }}
              >
                <div className="pendulumList">
                  {seats.FULL.map((item, key) => (
                    <div key={key} className="item" ref={listItemRef}>
                      {key === seats.GOV.length && <div className="zero"></div>}

                      {key === seats.GOV.length - majorityIndex && (
                        <div className="indicator lose-majority">
                          <IonLabel>
                            Government
                            <br />
                            loses majority
                          </IonLabel>
                        </div>
                      )}

                      {key === swingStatus.govMostlySafe && (
                        <div className="safe-indicator gov">
                          <IonLabel></IonLabel>
                        </div>
                      )}

                      {key === swingStatus.govSafe && (
                        <div className="safe-indicator gov">
                          <IonLabel>Safe</IonLabel>
                        </div>
                      )}

                      {key === swingStatus.govVerySafe && (
                        <div className="safe-indicator gov">
                          <IonLabel>Very Safe</IonLabel>
                        </div>
                      )}

                      {key === swingStatus.oppMostlySafe && (
                        <div className="safe-indicator opp">
                          <IonLabel></IonLabel>
                        </div>
                      )}

                      {key === swingStatus.oppSafe && (
                        <div className="safe-indicator opp">
                          <IonLabel>Safe</IonLabel>
                        </div>
                      )}

                      {key === swingStatus.oppVerySafe && (
                        <div className="safe-indicator opp">
                          <IonLabel>Very Safe</IonLabel>
                        </div>
                      )}

                      <IonRow>
                        <IonCol size="7">
                          <span
                            style={{ background: "currentColor" }}
                            className={
                              "party-block --party-" +
                              item["Elected Party (TCP) [Incumbent]"]
                                .toLowerCase()
                                .replace(" (np)", "") +
                              fadeColour(item["TCP Margin [Incumbent]"])
                            }
                          ></span>
                          <IonText className="party">
                            {item["Elected Party (TCP) [Incumbent]"]}
                          </IonText>
                          <IonText>{item["Electorate"]}</IonText>
                        </IonCol>
                        <IonCol size="2">
                          {item["Runner-up Party (TCP) [Incumbent]"] !==
                            "ALP" &&
                            ![
                              "LNP",
                              "LP",
                              "NP",
                              "NAT",
                              "CLP",
                              "LNP (NP)",
                              "LIB",
                            ].includes(
                              item["Runner-up Party (TCP) [Incumbent]"]
                            ) && (
                              <IonText className="runnerUp">
                                v {item["Runner-up Party (TCP) [Incumbent]"]}
                              </IonText>
                            )}

                          {item["Elected Party (TCP) [Incumbent]"] !== "ALP" &&
                            ![
                              "LNP",
                              "LP",
                              "NP",
                              "NAT",
                              "CLP",
                              "LNP (NP)",
                              "LIB",
                            ].includes(
                              item["Elected Party (TCP) [Incumbent]"]
                            ) && (
                              <IonText className="runnerUp">
                                v {item["Runner-up Party (TCP) [Incumbent]"]}
                              </IonText>
                            )}

                          {viewId === "WA" &&
                            item["Elected Party (TCP) [Incumbent]"] !== "ALP" &&
                            ["LNP", "LP", "NP", "NAT", "LIB"].includes(
                              item["Runner-up Party (TCP) [Incumbent]"]
                            ) && (
                              <IonText className="runnerUp">
                                v {item["Runner-up Party (TCP) [Incumbent]"]}
                              </IonText>
                            )}
                        </IonCol>
                        <IonCol size="3">
                          <IonText className="bold percentage-swing number-font">
                            {item["TCP Margin [Incumbent]"].toFixed(2)}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </div>
                  ))}
                </div>
              </motion.div>
            </IonCol>
          </IonRow>
        </>
      )}
    </>
  );
};

export default PendulumSlider;
