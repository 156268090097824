import React from 'react'

export const Rotate = () => {
  return (
    <>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1883 77.1855C23.4393 73.4364 23.4393 67.358 27.1883 63.609L73.009 17.7883C76.7581 14.0392 82.8365 14.0392 86.5855 17.7883L187.561 118.763C191.31 122.513 191.31 128.591 187.561 132.34L141.74 178.161C137.991 181.91 131.913 181.91 128.164 178.161L27.1883 77.1855Z" fill="#222222"/>
        <path d="M116.117 165.507L131.138 160.502L137.809 180.522L122.789 185.527L116.117 165.507Z" fill="#222222"/>
        <path d="M62.177 20.2915L77.1974 15.2861L83.8689 35.3063L68.8485 40.3117L62.177 20.2915Z" fill="#222222"/>
        <path d="M12.7882 81.9855C9.0392 78.2364 9.0392 72.158 12.7883 68.409L58.6089 22.5883C62.358 18.8392 68.4364 18.8392 72.1855 22.5883L173.161 123.563C176.91 127.313 176.91 133.391 173.161 137.14L127.34 182.961C123.591 186.71 117.513 186.71 113.763 182.961L12.7882 81.9855Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M62.0031 25.9824L16.1824 71.8031C14.3079 73.6776 14.3079 76.7168 16.1824 78.5913L117.158 179.567C119.032 181.441 122.071 181.441 123.946 179.567L169.767 133.746C171.641 131.871 171.641 128.832 169.767 126.958L68.7913 25.9824C66.9168 24.1079 63.8776 24.1079 62.0031 25.9824ZM12.7883 68.409C9.0392 72.158 9.0392 78.2364 12.7882 81.9855L113.763 182.961C117.513 186.71 123.591 186.71 127.34 182.961L173.161 137.14C176.91 133.391 176.91 127.313 173.161 123.563L72.1855 22.5883C68.4364 18.8392 62.358 18.8392 58.6089 22.5883L12.7883 68.409Z" fill="#222222"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22 145V131C22 129.895 22.8954 129 24 129H32C33.1046 129 34 129.895 34 131V145C34 159.359 45.6406 171 60 171H74C75.1046 171 76 171.895 76 173V181C76 182.105 75.1046 183 74 183H60C39.0132 183 22 165.987 22 145Z" fill="#222222"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M178 56V70C178 71.1046 177.105 72 176 72H168C166.895 72 166 71.1046 166 70V56C166 41.6406 154.359 30 140 30H126C124.895 30 124 29.1046 124 28V20C124 18.8954 124.895 18 126 18H140C160.987 18 178 35.0132 178 56Z" fill="#222222"/>
        <path d="M44.5858 137.586C45.8457 138.846 44.9534 141 43.1716 141H12.8284C11.0466 141 10.1543 138.846 11.4142 137.586L26.5858 122.414C27.3668 121.633 28.6332 121.633 29.4142 122.414L44.5858 137.586Z" fill="#222222"/>
        <path d="M155.414 64.4142C154.154 63.1543 155.047 61 156.828 61H187.172C188.953 61 189.846 63.1543 188.586 64.4142L173.414 79.5858C172.633 80.3668 171.367 80.3668 170.586 79.5858L155.414 64.4142Z" fill="#222222"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M94.8978 137.896C94.8978 137.896 91.598 141.196 88.2981 137.896C84.9983 134.596 78.3987 121.397 94.8978 104.898C111.397 88.3986 124.596 94.9983 127.896 98.2981C131.196 101.598 127.896 104.898 127.896 104.898L94.8978 137.896ZM91.598 101.598C94.2235 98.9725 95.6985 95.4115 95.6985 91.6985C95.6985 87.9854 94.2235 84.4245 91.598 81.799C88.9725 79.1735 85.4115 77.6985 81.6985 77.6985C77.9855 77.6985 74.4245 79.1735 71.799 81.799C69.1735 84.4245 67.6985 87.9854 67.6985 91.6985C67.6985 95.4115 69.1735 98.9725 71.799 101.598C74.4245 104.223 77.9855 105.698 81.6985 105.698C85.4115 105.698 88.9725 104.223 91.598 101.598V101.598Z" fill="#D9D7D2"/>
      </svg>
    </>
  )
}