import React, { useState, useEffect, useMemo } from "react";
import { IonSearchbar, IonList } from "@ionic/react";
import ListDetail from "./ListDetail";
import "./SearchList.scss";
import Fuse from "fuse.js";
import { useView } from "../api/store";
import { CountView, View } from "../api/data-types";

// function isDefaultView(view: View | CountView): asserts view is View {}

const SearchList = (props) => {
  const [mode] = useView((view) => view.mode);
  let [data] = useView((view) =>
    view.mode === "default" ? view.incumbents : view.results
  );

  let [electorateAliases] = useView((view) => view.electorateAliases);
  let [searchText, setSearchText] = useState("");
  let [searchResult, setSearchResult] = useState([]);
  let [showList, setShowList] = useState(false);

  function formatSearchData(el: any) {
    if (mode === "default") {
      const result: View["incumbents"][number] = el;
      return {
        id: result.id,
        electorate: result.Electorate,
        state: result.State,
        electorateAliases: electorateAliases?.[result.Electorate] || [],
      };
    } else if (mode === "count") {
      const result: CountView["results"][number] = el;
      return {
        id: result.id,
        electorate: result.Electorate,
        state: result.State,
        electorateAliases: electorateAliases?.[result.Electorate] || [],
      };
    }
  }

  // @ts-ignore
  let formattedSearchData = useMemo(
    // @ts-ignore
    () => data.map(formatSearchData),
    // eslint-disable-next-line
    [data, electorateAliases]
  );

  let noDupes = useMemo(() => {
    const seen = new Set();
    const reducedData = formattedSearchData.filter((el) => {
      const duplicate = seen.has(el.electorate);
      seen.add(el.electorate);
      return !duplicate;
    });
    return reducedData;
  }, [formattedSearchData]);

  let fuse = useMemo(() => {
    return new Fuse(formattedSearchData, {
      shouldSort: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["electorate", "electorateAliases"],
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fuse.setCollection(formattedSearchData);
  }, [fuse, formattedSearchData]);

  useEffect(() => {
    if (searchText)
      setSearchResult(
        fuse.search(searchText).map((result) => {
          return result.item;
        })
      );
    else setSearchResult([]);
  }, [fuse, searchText, formattedSearchData]);

  return (
    <>
      <IonSearchbar
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value)}
        onIonFocus={() => {
          setShowList(true);
        }}
        onIonBlur={() => setShowList(false)}
        onIonClear={() => setShowList(false)}
        mode="ios"
      ></IonSearchbar>

      <IonList
        className={
          searchText || showList ? "searchlist show" : "searchlist hide"
        }
      >
        <ListDetail
          data={(searchText ? searchResult : noDupes) || []}
          select={props.select}
        />
      </IonList>
    </>
  );
};

export default SearchList;
