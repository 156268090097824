import React from 'react'

export const Racq = () => {
  return (
    <>
      <svg width="94" height="24" viewBox="0 0 94 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="racq">
        <path d="M88.4185 13.6512C86.5182 13.6512 85.0358 14.4449 83.9273 15.7148L80.4436 19.8422C82.0271 21.7471 85.0358 22.8583 86.461 21.1121L90.7365 16.0323C91.8449 14.6036 93.1117 13.8099 93.4285 13.4924L88.4185 13.6512Z" fill="black"/>
        <path d="M85.1949 22.5415C81.8695 23.9702 79.8109 22.0653 78.3858 20.4779L74.4269 15.7156C73.3185 14.4456 72.0517 13.6519 70.1514 13.6519H66.6677C67.1427 13.9694 68.2512 14.7631 69.3597 16.1918L70.9432 17.938C70.1514 18.2555 69.3597 18.4142 68.5679 18.4142C65.5592 18.4142 63.8173 16.3506 63.659 12.6995C63.659 8.25465 66.5093 5.23853 70.3098 5.23853C73.1601 5.23853 75.2187 7.46093 75.2187 10.6358C75.2187 11.747 75.0603 12.8582 74.7436 13.6519C75.5354 14.1282 76.0105 14.6044 76.6439 15.2394L78.8608 17.938C80.286 15.8743 81.0777 13.1757 81.0777 10.3183C81.0777 6.98471 79.6526 3.96858 77.2773 2.06366C75.5354 0.634972 73.3185 0 70.6265 0C66.9844 0 63.5006 1.42869 61.1253 4.12732C58.9084 6.50847 57.7999 9.5246 57.7999 13.0169C57.7999 17.303 60.0169 20.7954 63.8173 22.5415C65.0841 23.1765 66.6677 23.3353 67.9345 23.3353C71.5766 23.3353 73.1601 22.3828 74.2686 21.9066C75.6938 23.3353 77.594 23.9702 79.9693 23.9702C82.1862 24.129 83.9281 23.6528 85.1949 22.5415Z" fill="black"/>
        <path d="M70.1506 13.6512C72.0509 13.6512 73.3177 14.4449 74.4261 15.7149L78.385 20.4772C79.8101 22.0646 81.8687 23.9695 85.1941 22.5408C83.2939 22.8583 81.3936 22.0646 80.1268 20.4772L76.168 15.7149C75.0595 14.4449 73.7927 13.6512 71.8925 13.6512H70.1506Z" fill="black"/>
        <path d="M40.2203 13.3347C40.2203 10.0011 41.1705 6.82619 43.5458 4.44504C46.2377 1.58767 49.5631 0.317719 53.0469 0.317719C56.689 0.317719 59.381 1.74641 60.6478 3.1751L59.2226 4.92127C58.4309 5.87373 57.9558 6.19122 57.3224 6.19122C56.689 6.19122 56.214 6.19122 55.4222 5.87373C54.7888 5.55625 53.8387 5.23876 52.8885 5.23876C48.9297 5.23876 46.0794 8.25488 46.0794 12.8584C46.0794 17.3033 48.613 18.4145 50.83 18.4145C52.2551 18.4145 53.6803 17.9382 54.3137 17.6207C54.9471 17.3033 55.5805 17.1445 56.0556 17.1445C56.8474 17.1445 57.3224 17.462 57.7975 18.2557L59.0643 20.1606C58.1142 20.9543 55.5806 23.3355 50.3549 23.3355C49.5632 23.3355 47.8213 23.3355 45.921 22.5418C43.0707 21.2718 40.2203 18.097 40.2203 13.3347Z" fill="black"/>
        <path d="M36.4211 0.635254H29.7703L19.319 20.3194L17.4188 16.6683C16.3103 14.6047 15.8352 13.811 15.0435 13.4935C18.3689 12.541 19.9524 10.7948 20.4275 7.77871C20.9025 4.76258 19.319 2.85766 17.2604 1.58771C15.6769 0.635254 12.9849 0.635254 9.97621 0.635254H3.32539L0 23.1768H5.85904L7.12586 14.2872H7.60092C8.70939 14.2872 10.768 14.6047 11.8764 16.8271C11.8764 16.8271 14.2517 21.1131 14.2517 21.2719C15.2018 23.1768 15.9936 23.3356 17.8938 23.3356H21.2192C22.6444 23.3356 24.0696 23.3356 25.0197 21.4306L26.6032 18.5733H34.6792L35.3126 21.4306C35.6293 23.3356 37.2128 23.3356 38.638 23.3356H41.4883L36.4211 0.635254ZM14.7268 7.46122C14.5684 8.41368 13.7767 10.1599 9.97621 10.1599H7.75926L8.55102 4.76258H11.0847C14.8851 4.76258 14.8851 6.50876 14.7268 7.46122ZM28.5034 14.2872L32.1456 7.46122L33.5707 14.2872H28.5034Z" fill="black"/>
      </svg>
    </>
  )
}