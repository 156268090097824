import React, { useState } from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "./Drawer.scss";
import { AaaLogo } from "../assets/svg/aaaLogo";
import { Racq } from "../assets/svg/racq";
import { Rac } from "../assets/svg/rac";
import { Ract } from "../assets/svg/ract";
import { Raa } from "../assets/svg/raa";
import { Aant } from "../assets/svg/aant";
import { Nrma } from "../assets/svg/nrma";
import { Racv } from "../assets/svg/racv";

import DrawerModal from "./DrawerModal";
import { store, useView } from "../api/store";
import { ViewSelector } from "./view-selector/view-selector";
import { useSelectable } from "../utils/state/selectable";

const empty_array = [];

const Drawer = () => {
  let [pages] = useView((view) => view?.pages || empty_array);
  let [viewID] = useView((view) => view?.id);
  let open = useSelectable(store, (state) => state.menuOpen);
  const [showModal, setShowModal] = useState(false);

  const [modalData, setmodalData] = useState<any>({});

  return (
    <>
      <div className={open ? "drawer open" : "drawer"}>
        <IonContent color="dark">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className="logo-block">
                  <AaaLogo />
                  {viewID === "QLD" && <Racq />}
                  {viewID === "WA" && <Rac />}
                  {viewID === "TAS" && <Ract />}
                  {viewID === "SA" && <Raa />}
                  {viewID === "NT" && <Aant />}
                  {viewID === "ACT" && <Nrma />}
                  {viewID === "NSW" && <Nrma />}
                  {viewID === "VIC" && <Racv />}
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <ViewSelector />
              </IonCol>
              {pages.length !== 0 && (
                <IonCol size="12">
                  <IonList>
                    {pages.map((item, key) => (
                      <IonItem
                        key={key}
                        lines="full"
                        onClick={() => {
                          setmodalData(pages[key]);
                          setShowModal(true);
                        }}
                      >
                        <IonLabel>{item.Page}</IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>

        <div
          className="backdrop"
          onClick={() => {
            store.set((state) => {
              state.menuOpen = false;
            });
          }}
        ></div>
      </div>

      <DrawerModal
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        data={modalData}
      />
    </>
  );
};

export default Drawer;
