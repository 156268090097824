import React, { useState, useEffect, useMemo } from "react";
import {
  IonSearchbar,
  IonContent,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import Fuse from "fuse.js";
import "./SearchDirectory.scss";
import { IconRight } from "../assets/svg/right";
import { useView } from "../api/store";
import { Candidate } from "../api/data-types";
import { Link } from "react-router-dom";
import { slugify } from "../utils/string/slugify";

// function addAliases(aliases: View['aliases']) {
//   return (candidate: Candidate) => {
//     return {
//       ...candidate,
//       aliases: aliases?.[candidate.party] || [],
//     }
//   }
// }

const SearchDirectory = (props) => {
  let [searchText, setSearchText] = useState("");
  let [candidates, set] = useView((view) => view.candidates);
  let [aliases] = useView((view) => view.aliases);
  let [electorateAliases] = useView((view) => view.electorateAliases);
  let [searchResults, setSearchResults] = useState<
    Fuse.FuseResult<Candidate & { aliases: string[] }>[]
  >([]);
  let [electorate] = useView((view) => view.id);

  function addDataToCandidates(candidate: Candidate) {
    return {
      ...candidate,
      aliases: [
        ...(aliases?.[candidate.party] || []),
        ...(electorateAliases?.[candidate.electorate] || []),
      ],
    };
  }

  let fuse = useMemo(() => {
    return new Fuse(candidates.map(addDataToCandidates), {
      shouldSort: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["name", "party", "electorate", "state", "aliases"],
    });
  }, []); // eslint-disable-line

  /* Update the fuse collection when candidates change */
  useEffect(() => {
    fuse.setCollection(candidates.map(addDataToCandidates));
    // eslint-disable-next-line
  }, [candidates, fuse, aliases, electorateAliases]);

  useEffect(() => {
    if (searchText) setSearchResults(fuse.search(searchText));
    else setSearchResults([]);
  }, [candidates, fuse, searchText, aliases, electorateAliases]);

  return (
    <>
      <IonSearchbar
        mode="ios"
        placeholder="Filter"
        value={searchText}
        onIonChange={(e) => {
          setSearchText(e.detail.value);
        }}
      />

      <IonContent className="searchDirectory">
        <IonList>
          {!!searchResults.length &&
            searchResults.map((item) => (
              <SearchResultItem
                {...item.item}
                key={item.item.name + item.item.email + item.item.electorate}
                onClick={() => {
                  set((view) => {
                    view.appTab = "map";
                    view.modal = {
                      electorate: item.item.electorate,
                      tab: "candidates",
                    };
                  });
                }}
              />
            ))}
          {!searchResults.length &&
            candidates.map((item) => (
              <SearchResultItem
                {...item}
                key={item.name + item.email + item.electorate}
                onClick={() => {
                  set((view) => {
                    view.appTab = "map";
                    view.modal = {
                      electorate: item.electorate,
                      tab: "candidates",
                    };
                  });
                }}
              />
            ))}
        </IonList>
        {electorate === "FED" && (
          <IonText class="list-disclaimer">
            {/* { electorate === "QLD" && (<>A final list of candidates will be updated at the close of candidate nominations in mid-October.</>)} */}
            A list of candidates will become available during the next federal
            election cycle.
          </IonText>
        )}
      </IonContent>
    </>
  );
};

function SearchResultItem({ name, party, electorate, state, onClick }) {
  return (
    <Link to={`directory/electorate/${slugify(electorate)}/candidates`}>
      <IonItem lines="full">
        <IonRow onClick={onClick}>
          <IonCol size="5">
            <IonText className="bold">{name}</IonText>
          </IonCol>
          <IonCol size="2">
            <IonText className={"bold --party-" + party?.toLowerCase()}>
              {party}{" "}
            </IonText>
          </IonCol>
          <IonCol size="5">
            <IonText className="justify-content-between">
              <span>
                {electorate}, {state}
              </span>{" "}
              <span>
                <IconRight />
              </span>
            </IonText>
          </IonCol>
        </IonRow>
      </IonItem>
    </Link>
  );
}

export default SearchDirectory;
