import React from 'react'

export const IconMap = () => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 15L11 13V7.858C12.721 7.411 14 5.858 14 4C14 1.794 12.206 0 10 0C7.794 0 6 1.794 6 4C6 5.858 7.279 7.411 9 7.858V13L10 15Z" fill="black"/>
        <path d="M14.267 8.563L13.734 10.491C16.325 11.207 18 12.584 18 14C18 15.892 14.715 18 10 18C5.285 18 2 15.892 2 14C2 12.584 3.675 11.207 6.267 10.49L5.734 8.562C2.197 9.54 0 11.623 0 14C0 17.364 4.393 20 10 20C15.607 20 20 17.364 20 14C20 11.623 17.803 9.54 14.267 8.563Z" fill="black"/>
      </svg>
    </>
  )
}