import React, { useMemo } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import "./ListDetail.scss";

const ListDetail = (props) => {
  let results = useMemo(() => {
    const seen = new Set();
    const reducedData = props.data.filter((el) => {
      const duplicate = seen.has(el.electorate);
      seen.add(el.electorate);
      return !duplicate;
    });
    return reducedData;
  }, [props.data]);

  return (
    <>
      {results.map((el, key) => (
        <IonItem
          key={key}
          lines="full"
          onClick={() => {
            if (el.electorate) {
              props.select(el.electorate);
            } else {
              props.select(el.item.electorate);
            }
          }}
        >
          <IonLabel>
            {el.electorate ? el.electorate : el.item.electorate},{" "}
            {el.state ? el.state : el.item.state}
          </IonLabel>
        </IonItem>
      ))}
    </>
  );
};

export default ListDetail;
