import React, { useState, useMemo, useEffect, useRef } from 'react'
import { store } from '../../api/store'
import { useSelectable } from '../../utils/state/selectable'
import './pendulumColours.scss'
import { useMeasure } from '../../utils/hooks/use-measure'


const GOV_SIDE = [
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M508.66,791.83l-10.03,95.47c4.64,0.49,8.71,0.7,13.38,0.7v-96C510.87,792,509.75,791.94,508.66,791.83z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M505.35,791.3l-19.96,93.9c4.56,0.97,8.59,1.61,13.23,2.1l10.03-95.47C507.53,791.71,506.43,791.53,505.35,791.3z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M502.11,790.43l-29.67,91.3c4.44,1.44,8.38,2.5,12.94,3.47l19.96-93.9C504.25,791.07,503.17,790.78,502.11,790.43z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M498.99,789.23l-39.05,87.7c4.26,1.9,8.07,3.36,12.51,4.8l29.67-91.3C501.04,790.09,500,789.68,498.99,789.23z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M496,787.71l-48,83.14c4.04,2.33,7.68,4.18,11.94,6.08l39.05-87.7C497.96,788.77,496.97,788.27,496,787.71z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M493.19,785.88l-56.43,77.67c3.77,2.74,7.2,4.96,11.24,7.3l48-83.14C495.03,787.15,494.1,786.54,493.19,785.88z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M490.59,783.78l-64.24,71.35c3.47,3.12,6.64,5.69,10.41,8.43l56.43-77.67C492.29,785.23,491.42,784.52,490.59,783.78z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M488.22,781.41l-71.35,64.24c3.12,3.47,6.01,6.35,9.47,9.47l64.24-71.35C489.76,783.03,488.97,782.24,488.22,781.41z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M486.12,778.81l-77.67,56.43c2.74,3.77,5.31,6.94,8.43,10.41l71.35-64.24C487.48,780.58,486.77,779.71,486.12,778.81z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M484.29,776l-83.14,48c2.33,4.04,4.55,7.46,7.3,11.24l77.67-56.43C485.46,777.9,484.85,776.97,484.29,776z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M482.77,773.01l-87.7,39.05c1.9,4.26,3.75,7.9,6.08,11.94l83.14-48C483.73,775.03,483.23,774.04,482.77,773.01z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M481.57,769.89l-91.3,29.67c1.44,4.44,2.9,8.25,4.8,12.51l87.7-39.05C482.32,772,481.91,770.96,481.57,769.89z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480.7,766.65l-93.9,19.96c0.97,4.56,2.03,8.5,3.47,12.94l91.3-29.67C481.22,768.83,480.93,767.75,480.7,766.65z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480.17,763.34l-95.47,10.03c0.49,4.64,1.13,8.67,2.1,13.23l93.9-19.96C480.47,765.57,480.29,764.47,480.17,763.34z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,760h-96c0,4.67,0.21,8.74,0.7,13.38l95.47-10.03C480.06,762.25,480,761.13,480,760z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,760h-96v-8h96V760z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,752h-96v-8h96V752z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,744h-96v-8h96V744z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,736h-96v-8h96V736z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,728h-96v-8h96V728z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,720h-96v-8h96V720z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,712h-96v-8h96V712z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,704h-96v-8h96V704z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,696h-96v-8h96V696z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,688h-96v-8h96V688z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,680h-96v-8h96V680z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,672h-96v-8h96V672z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,664h-96v-8h96V664z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,656h-96v-8h96V656z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,648h-96v-8h96V648z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,640h-96v-8h96V640z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,632h-96v-8h96V632z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,624h-96v-8h96V624z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,616h-96v-8h96V616z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,608h-96v-8h96V608z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,600h-96v-8h96V600z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,592h-96v-8h96V592z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,584h-96v-8h96V584z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,576h-96v-8h96V576z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,568h-96v-8h96V568z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,560h-96v-8h96V560z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,552h-96v-8h96V552z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,544h-96v-8h96V544z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,536h-96v-8h96V536z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,528h-96v-8h96V528z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,520h-96v-8h96V520z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,512h-96v-8h96V512z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,504h-96v-8h96V504z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,496h-96v-8h96V496z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,488h-96v-8h96V488z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,480h-96v-8h96V480z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,472h-96v-8h96V472z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,464h-96v-8h96V464z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,456h-96v-8h96V456z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,448h-96v-8h96V448z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,440h-96v-8h96V440z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,432h-96v-8h96V432z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,424h-96v-8h96V424z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,416h-96v-8h96V416z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,408h-96v-8h96V408z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,400h-96v-8h96V400z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,392h-96v-8h96V392z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,384h-96v-8h96V384z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,376h-96v-8h96V376z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,368h-96v-8h96V368z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,360h-96v-8h96V360z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,352h-96v-8h96V352z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,344h-96v-8h96V344z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,336h-96v-8h96V336z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,328h-96v-8h96V328z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,320h-96v-8h96V320z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,312h-96v-8h96V312z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,304h-96v-8h96V304z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,296h-96v-8h96V296z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,288h-96v-8h96V288z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,280h-96v-8h96V280z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,272h-96v-8h96V272z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,264h-96v-8h96V264z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,256h-96v-8h96V256z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,248h-96v-8h96V248z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,240h-96v-8h96V240z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,232h-96v-8h96V232z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,224h-96v-8h96V224z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,216h-96v-8h96V216z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,208h-96v-8h96V208z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,200h-96v-8h96V200z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,192h-96v-8h96V192z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,184h-96v-8h96V184z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,176h-96v-8h96V176z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,168h-96v-8h96V168z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,160h-96v-8h96V160z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,152h-96v-8h96V152z" {...p} /></g>,
  p => <g key={p.key} data-index={p.key} data-gov={p.gov}><path d="M480,144h-96v-8h96V144z" {...p} /></g>,
]

const NOT_GOV_SIDE = [
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,144h-96v-8h96V144z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,152h-96v-8h96V152z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,160h-96v-8h96V160z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,168h-96v-8h96V168z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,176h-96v-8h96V176z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,184h-96v-8h96V184z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,192h-96v-8h96V192z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,200h-96v-8h96V200z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,208h-96v-8h96V208z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,216h-96v-8h96V216z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,224h-96v-8h96V224z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,232h-96v-8h96V232z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,240h-96v-8h96V240z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,248h-96v-8h96V248z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,256h-96v-8h96V256z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,264h-96v-8h96V264z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,272h-96v-8h96V272z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,280h-96v-8h96V280z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,288h-96v-8h96V288z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,296h-96v-8h96V296z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,304h-96v-8h96V304z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,312h-96v-8h96V312z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,320h-96v-8h96V320z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,328h-96v-8h96V328z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,336h-96v-8h96V336z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,344h-96v-8h96V344z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,352h-96v-8h96V352z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,360h-96v-8h96V360z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,368h-96v-8h96V368z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,376h-96v-8h96V376z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,384h-96v-8h96V384z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,392h-96v-8h96V392z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,400h-96v-8h96V400z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,408h-96v-8h96V408z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,416h-96v-8h96V416z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,424h-96v-8h96V424z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,432h-96v-8h96V432z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,440h-96v-8h96V440z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,448h-96v-8h96V448z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,456h-96v-8h96V456z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,464h-96v-8h96V464z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,472h-96v-8h96V472z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,480h-96v-8h96V480z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,488h-96v-8h96V488z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,496h-96v-8h96V496z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,504h-96v-8h96V504z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,512h-96v-8h96V512z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,520h-96v-8h96V520z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,528h-96v-8h96V528z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,536h-96v-8h96V536z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,544h-96v-8h96V544z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,552h-96v-8h96V552z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,560h-96v-8h96V560z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,568h-96v-8h96V568z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,576h-96v-8h96V576z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,584h-96v-8h96V584z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,592h-96v-8h96V592z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,600h-96v-8h96V600z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,608h-96v-8h96V608z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,616h-96v-8h96V616z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,624h-96v-8h96V624z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,632h-96v-8h96V632z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,640h-96v-8h96V640z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,648h-96v-8h96V648z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,656h-96v-8h96V656z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,664h-96v-8h96V664z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,672h-96v-8h96V672z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,680h-96v-8h96V680z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,688h-96v-8h96V688z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,696h-96v-8h96V696z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,704h-96v-8h96V704z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,712h-96v-8h96V712z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,720h-96v-8h96V720z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,728h-96v-8h96V728z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,736h-96v-8h96V736z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,744h-96v-8h96V744z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,752h-96v-8h96V752z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M639.91,760h-96v-8h96V760z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M543.83,763.34l95.47,10.03c0.49-4.64,0.7-8.71,0.7-13.38h-96C544,761.13,543.94,762.25,543.83,763.34z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M543.3,766.65l93.9,19.96c0.97-4.56,1.61-8.59,2.1-13.23l-95.47-10.03C543.71,764.47,543.53,765.57,543.3,766.65z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M542.43,769.89l91.3,29.67c1.44-4.44,2.5-8.38,3.47-12.94l-93.9-19.96C543.07,767.75,542.78,768.83,542.43,769.89z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M541.23,773.01l87.7,39.05c1.9-4.26,3.36-8.07,4.8-12.51l-91.3-29.67C542.09,770.96,541.68,772,541.23,773.01z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M539.71,776l83.14,48c2.33-4.04,4.18-7.68,6.08-11.94l-87.7-39.05C540.77,774.04,540.27,775.03,539.71,776z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M537.88,778.81l77.67,56.43c2.74-3.77,4.96-7.2,7.3-11.24l-83.14-48C539.15,776.97,538.54,777.9,537.88,778.81z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M535.78,781.41l71.35,64.24c3.12-3.47,5.69-6.64,8.43-10.41l-77.67-56.43C537.23,779.71,536.52,780.58,535.78,781.41z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M533.41,783.78l64.24,71.35c3.47-3.12,6.35-6.01,9.47-9.47l-71.35-64.24C535.03,782.24,534.24,783.03,533.41,783.78z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M530.81,785.88l56.43,77.67c3.77-2.74,6.94-5.31,10.41-8.43l-64.24-71.35C532.58,784.52,531.71,785.23,530.81,785.88z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M528,787.71l48,83.14c4.04-2.33,7.46-4.55,11.24-7.3l-56.43-77.67C529.9,786.54,528.97,787.15,528,787.71z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M525.01,789.23l39.05,87.7c4.26-1.9,7.9-3.75,11.94-6.08l-48-83.14C527.03,788.27,526.04,788.77,525.01,789.23z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M521.89,790.43l29.67,91.3c4.44-1.44,8.25-2.9,12.51-4.8l-39.05-87.7C524,789.68,522.96,790.09,521.89,790.43z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M518.65,791.3l19.96,93.9c4.56-0.97,8.5-2.03,12.94-3.47l-29.67-91.3C520.83,790.78,519.75,791.07,518.65,791.3z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M515.34,791.83l10.03,95.47c4.64-0.49,8.67-1.13,13.23-2.1l-19.96-93.9C517.57,791.53,516.47,791.71,515.34,791.83z"  {...p}/></g>,
  p => <g key={p.key*100} data-index={p.key} data-gov={p.gov}><path d="M512,792v96c4.67,0,8.74-0.21,13.38-0.7l-10.03-95.47C514.25,791.94,513.13,792,512,792z" {...p} /></g>
]

export const PendulumElement = ({ seats }) => {
  let view = useSelectable(store, (state) => state.view)
  let majorityIndex = useMemo(() => seats.GOV.length - Math.floor(seats.FULL.length / 2), [seats])
  let [viewbox, setViewbox] = useState('0 120 1024 1024')
  let [ref, { width }] = useMeasure()
  let refB = useRef(null)
  
  const addSvgClasses = (key, side) => {
    const party = `--party-${seats[side][key][
      'Elected Party (TCP) [Incumbent]'
    ].toLowerCase().replace(' (np)', '')}`

    let fade = '-marginal'
    let margin = Number(seats[side][key]['TCP Margin [Incumbent]'])
    if (margin >= 15) {
      fade = '-very-safe'
    } else if (margin >= 10) {
      fade = '-safe'
    } else if (margin >= 5) {
      fade = '-mostly-safe'
    }
    const addLine = key === majorityIndex ? '-add-line' : ''
    return ['--seat', party, fade, addLine].join(' ')
  }

  useEffect(() => {
    let svg = refB.current
    let bb = svg.getBBox();
    let vb = [bb.x,bb.y,bb.width,bb.height];
    setViewbox(vb.join(" "))
  }, [width, view])

  /* prettier-ignore-start */
  return (
    <>
      {seats.NOT_GOV.length > 0 && seats.GOV.length > 0 && (
          <svg
          ref={applyRefs(ref, refB)}
            viewBox={viewbox}
            xmlns="http://www.w3.org/2000/svg"
          >
            {GOV_SIDE.filter((_, i) => i < seats.GOV.length).reverse().map((cb, i, arr) => cb({ key: arr.length - 1 - i, gov: "true", className: addSvgClasses(i, 'GOV') }))}
            {NOT_GOV_SIDE.filter((_, i, arr) => (arr.length - i) <= seats.NOT_GOV.length).reverse().map((cb, i) => cb({ key: i, gov: "false", className: addSvgClasses(i, 'NOT_GOV') }))}
            {[
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M497.78,895.25l10.87-103.43" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M505.35,791.3l-21.62,101.73" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M469.97,889.34l32.14-98.91" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M498.99,789.23l-42.3,95.01" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M444,877.78l52-90.07" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M493.19,785.88l-61.13,84.14" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M421,861.07l69.59-77.29" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M488.22,781.41L410.93,851" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M401.97,839.94l84.14-61.13" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M484.29,776l-90.07,52" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M387.76,815.32l95.01-42.3" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M481.57,769.89l-98.91,32.14" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M378.97,788.28l101.73-21.62" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480.17,763.34l-103.43,10.87" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,760h-95.74H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,752h104" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,744H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,736h104" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,728H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,720h104" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,712H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,704h104" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,696H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,688h104 M480,680H376" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M376,672h104" /></g>,
              <g className="majority-index"><path className={`indicator ${majorityIndex === 0 ? "hide" : ""}`} d="M480,664H376" /></g>,
            ].find((el, i) => {
              if (majorityIndex === 0) return i === 0;
              return i + 1 === majorityIndex
            })}
          </svg>
      )}
    </>
  )
}

function applyRefs (...refs) {
  return el => {
    refs.forEach(ref => {
      if (typeof ref === 'object') {
        ref.current = el
      } else {
        ref(el)
      }
    })
  }
}