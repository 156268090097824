import { Selectable } from '../utils/state/selectable'
import { ELECTORATES } from './constants'
import { fetchProgress } from './fetch-progress'
import { store } from './store'
import geobuf from 'geobuf'
import Pbf from 'pbf'

export async function loadFromJSON() {
  let totalProgress = new Selectable({
    progress: ELECTORATES.map(() => 0),
    totals: ELECTORATES.map(() => 0),
  })
  totalProgress.select(
    (state) =>
      state.progress.reduce((last, current, k, arr) => {
        return last + current / arr.length
      }, 0),
    (progress) =>
      store.set((state) => {
        state.loadingProgress = progress
      })
  )

  fetch(`/assets/data/data-manifest.json`)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      totalProgress.set((state) => {
        state.totals = data.flatMap((view) => {
          return [view.data.size, view.geo.size]
        })
      })
    })

  await Promise.all(
    ELECTORATES.map(async (electorate, i) => {
      let electorateProgress = new Selectable([0, 0])
      electorateProgress.select(
        (state) => (state[0] + state[1]) / 2,
        (progress) => {
          totalProgress.set((state) => {
            state[i] = progress
          })
          store.set((state) => {
            state.loadingViews[electorate.id] = progress
          })
        }
      )
      let [data, electorates] = await Promise.all([
        fetchProgress(
          fetch(`/assets/data/${electorate.id}.json`),
          (progress, total) => {
            let currentTotal = total ? total : totalProgress.state.totals[i]
            if (currentTotal) {
              electorateProgress.set((state) => {
                state[0] = progress / currentTotal
              })
            }
          }
        ),
        fetchProgress(
          fetch(`/assets/data/${electorate.id}-electorate.proto`),
          (progress, total) => {
            let currentTotal = total ? total : totalProgress.state.totals[i]
            if (currentTotal) {
              electorateProgress.set((state) => {
                state[1] = progress / total
              })
            }
          },
          (data) => {
            return geobuf.decode(new Pbf(data))
          }
        ),
      ])
      data.electorates = electorates
      store.set((state) => {
        state.views[electorate.id] = data
      })
    })
  )

  store.set((state) => {
    state.loadedAt = Date.now()
  })
}
