import React, { useState } from "react";
import { IonText, IonButton } from "@ionic/react";
import "./ModalTabAccordionCandidate.scss";
import { IconRight } from "../assets/svg/right";
import { Candidate } from "../api/data-types";

const AccordionCandidate = ({ candidate }: { candidate: Candidate }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={
          open
            ? "accordion accordion--candidate open"
            : "accordion accordion--candidate"
        }
        onClick={handleClick}
      >
        <div className="row row-info">
          <IonText className="bold col-name">{candidate.name}</IonText>

          <IonText
            className={
              "bold col-party --party-" + candidate?.party?.toLowerCase()
            }
          >
            {candidate.party}{" "}
          </IonText>

          <IonText className="col-electorate">
            <span>
              {candidate.electorate}, {candidate.state}
            </span>{" "}
          </IonText>

          <div className="col-arrow arrow">
            {(candidate.email || candidate.phone) && <IconRight />}
          </div>
        </div>

        <div className="row row-button">
          {candidate.email && (
            <IonButton
              color="primary"
              size="small"
              fill="outline"
              href={"mailto:" + candidate.email}
            >
              Email
            </IonButton>
          )}
          {candidate.phone && (
            <IonButton
              color="primary"
              size="small"
              fill="outline"
              href={"tel:" + candidate.phone}
            >
              Call
            </IonButton>
          )}
        </div>
      </div>
    </>
  );
};

export default AccordionCandidate;
