import React from 'react'

export const Rac = () => {
  return (
    <>
      <svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.9615 3.47084L22.2638 2.75447C23.6776 1.12401 25.2933 0.410675 27.0101 0.410675C28.5248 0.410675 30 0.5 31.6554 2.14305L51.9532 22.6258C54.0739 24.6639 54.7808 28.842 51.9532 31.6953C51.6503 32.001 38.0174 44.5352 37.0075 45.4524C36.8714 45.5792 36.7363 45.7071 36.6018 45.8345C35.4391 46.9357 34.3154 48 32.8672 48H20.749C19.2343 48 17.9215 47.7962 16.6087 46.5733C15.5988 45.6562 2.06693 32.001 1.86496 31.6953C-0.962602 28.7401 -0.255711 24.6639 1.86496 22.6258C1.96595 22.5239 22.2638 2.75447 22.2638 2.75447C22.9615 3.47084 22.9617 3.47069 22.9615 3.47084L12.8 13.3683C10.2565 15.8458 7.70979 18.3265 5.7927 20.1942L2.80888 23.1018C2.70769 23.2005 2.64082 23.2657 2.60547 23.3002C2.58452 23.3207 2.57464 23.3303 2.57527 23.3297L2.56668 23.3384L2.55789 23.3468C0.789585 25.0463 0.183098 28.491 2.5875 31.004L2.64972 31.069L2.66662 31.0946C2.69151 31.122 2.72887 31.1622 2.77998 31.2163C2.88614 31.3288 3.03896 31.4881 3.23371 31.6894C3.62252 32.0912 4.16966 32.6513 4.82553 33.3195C6.13681 34.6555 7.87758 36.4185 9.64417 38.2011C13.1952 41.7846 16.8016 45.3977 17.281 45.8331L17.2903 45.8415C18.3391 46.8185 19.3534 47 20.749 47H32.8672C33.3833 47 33.886 46.7934 34.4648 46.3746C34.9364 46.0334 35.3804 45.6132 35.8868 45.134C36.0277 45.0006 36.1734 44.8627 36.3259 44.7207L36.3352 44.7121C36.837 44.2563 40.4943 40.8967 44.0684 37.6086C45.8545 35.9655 47.6182 34.3417 48.9543 33.1097C49.6223 32.4936 50.1831 31.9759 50.5861 31.6028C50.7877 31.4161 50.9492 31.2663 51.0647 31.1586C51.1225 31.1047 51.1679 31.0622 51.2007 31.0313C51.2227 31.0106 51.235 30.9989 51.2405 30.9935C51.2447 30.9895 51.2451 30.9892 51.2429 30.9914C53.6236 28.589 53.0359 25.0533 51.2603 23.3468L51.2515 23.3384L30.9487 2.85065C30.2296 2.13763 29.5883 1.79585 28.9947 1.61916C28.3795 1.43606 27.7582 1.41068 27.0101 1.41068C25.6116 1.41068 24.2625 1.97587 23.0193 3.40959L22.9917 3.44144L22.9615 3.47084Z" fill="#222222"/>
        <path d="M31.6562 2.14271C29.2326 -0.506799 25.0922 -0.914416 22.1637 2.14271C22.1637 2.14271 1.76487 22.7274 1.86585 22.6255C8.6318 16.715 23.6784 2.24462 31.8582 2.34652C31.7572 2.24462 31.7572 2.24462 31.6562 2.14271Z" fill="#222222"/>
        <path d="M28.4241 27.7217H26.0005L28.0202 24.155L28.4241 27.7217ZM32.2616 32.1036L30.2419 20.6903H27.7172C27.4143 20.6903 26.7074 20.7922 26.1015 21.7093C25.5966 22.5246 20.7493 30.2693 20.7493 30.2693V32.1036H23.4759L24.5867 30.2693H28.8281L29.0301 32.1036H32.2616Z" fill="#222222"/>
        <path d="M17.8213 24.7642C17.6193 25.5794 16.9124 25.987 15.8016 25.987H14.2868L14.7918 23.5413H16.2055C17.4174 23.5413 17.9223 23.949 17.8213 24.7642ZM11.5602 21.4014L11.3583 22.5223L9.1366 32.1013H12.7721L13.7819 27.7194C14.6908 27.7194 16.3065 27.9232 16.2055 30.7766C16.2055 31.0823 16.2055 31.9994 16.1046 32.1013H20.043C20.043 31.6937 20.043 31.388 20.043 30.8785C20.043 29.9613 20.043 29.1461 19.841 28.5347C19.639 27.9232 19.3361 27.3118 18.9321 26.9042C19.5381 26.7004 20.9518 25.987 21.4568 24.0509C21.7597 23.0318 21.6587 20.688 17.8213 20.5861H11.2573L11.5602 21.4014Z" fill="#222222"/>
        <path d="M39.8367 27.7194C39.7357 27.9232 39.5337 28.127 39.3317 28.2289C38.8268 28.6366 38.2209 28.8404 37.514 28.8404C36.8071 28.8404 36.3022 28.6366 35.8983 28.127C35.5953 27.6175 35.4944 27.108 35.5953 26.3947C35.7973 25.6813 36.1003 25.0699 36.7062 24.5604C37.3121 24.0509 37.918 23.8471 38.6249 23.8471C39.6347 23.8471 40.2406 24.3566 40.4426 25.2737H44.179C44.179 24.1528 43.7751 23.0318 43.2702 22.2166C42.3613 21.0956 41.1495 20.5861 39.4327 20.5861C37.716 20.5861 36.2012 21.0956 34.7874 22.2166C33.3737 23.3375 32.4648 24.6623 32.1619 26.2928C31.7579 27.9232 32.0609 29.248 32.9697 30.3689C33.8786 31.4899 35.1914 31.9994 36.8071 31.9994C38.4229 31.9994 39.9377 31.4899 41.1495 30.5727C42.0583 29.8594 42.9672 28.7385 43.4721 27.6175H39.8367V27.7194Z" fill="#222222"/>
        <path d="M52.055 31.5927C50.5403 32.9174 35.4936 46.165 20.9518 47.6935C20.9518 47.6935 20.9518 47.9993 21.0528 47.9993C22.6685 47.9993 33.0699 47.9993 33.0699 47.9993C34.6857 47.9993 35.8975 47.8974 37.2103 46.5726C38.6241 45.2478 52.055 31.5927 52.055 31.5927Z" fill="#222222"/>
      </svg>
    </>
  )
}