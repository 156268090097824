import React from 'react'

export const IconBack = () => {
  return (
    <>
      <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8H21M2 8L9 1M2 8L9 15" stroke="black" strokeWidth="2"/>
      </svg>
      </>
  )
}
