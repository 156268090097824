import React, { useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import './Directory.css'
import SearchDirectory from '../../../components/SearchDirectory'

const Directory: React.FC<any> = () => {
  // Load QLD candidates.

  const [showModal, setShowModal] = useState(false) // eslint-disable-line
  const [modalData, setModalData] = useState('') // eslint-disable-line

  const getModalData = (electorate) => {
    setModalData(electorate)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle size="large">Directory</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SearchDirectory modal={getModalData} />
      </IonContent>
    </IonPage>
  )
}

export default Directory
