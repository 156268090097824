import React from 'react'
import { store } from '../api/store'
import { useSelectable } from '../utils/state/selectable'
import './Hamburger.scss'

const Hamburger = () => {
  let menuOpen = useSelectable(store, (state) => state.menuOpen)

  return (
    <button
      onClick={() =>
        store.set((state) => {
          state.menuOpen = true
        })
      }
      className={menuOpen ? 'hamburger open' : 'hamburger'}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default Hamburger
