import React, { useEffect, useLayoutEffect, useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { IconDown } from "../../assets/svg/down";
import { store } from "../../api/store";
import { useSelectable } from "../../utils/state/selectable";
import { STATE_ID } from "../../api/data-types";
import "./view-selector.scss";

export const ViewSelector = ({
  onChange,
  view,
}: {
  onChange?: (val: STATE_ID) => any;
  view?: STATE_ID;
}) => {
  let currentView = useSelectable(store, (state) => state.view);
  let [state, setState] = useState(currentView);
  let isControlled = !!onChange;

  useLayoutEffect(() => {
    setState(currentView);
  }, [currentView]);

  useEffect(() => {
    if (!isControlled) {
      store.set((s) => {
        s.view = state;
      });
    }
  }, [isControlled, state]);

  return (
    <div className="selector">
      <IonSelect
        value={isControlled ? view || undefined : state}
        placeholder="Select your region"
        onIonChange={(e) => {
          e.preventDefault();
          if (onChange) onChange(e.detail.value);
          else {
            setState(e.detail.value);
          }
        }}
        mode="md"
      >
        <IonSelectOption value="FED">Federal</IonSelectOption>
        <IonSelectOption value="VIC">Victoria</IonSelectOption>
        <IonSelectOption value="NSW">New South Wales</IonSelectOption>
        <IonSelectOption value="SA">South Australia</IonSelectOption>
        <IonSelectOption value="QLD">Queensland</IonSelectOption>
        <IonSelectOption value="TAS">Tasmania</IonSelectOption>
        <IonSelectOption value="WA">Western Australia</IonSelectOption>
        <IonSelectOption value="NT">Northern Territory</IonSelectOption>
        <IonSelectOption value="ACT">
          Australian Capital Territory
        </IonSelectOption>
      </IonSelect>
      <IconDown />
    </div>
  );
};
