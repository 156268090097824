import React, { Fragment, useState, useMemo } from "react";
import { IonRow, IonCol, IonText } from "@ionic/react";
import "./ModalTabAccordion.scss";
import { Result } from "../api/data-types";
import { Graph, CountGraph } from "./graph/Graph";
import { IconRight } from "../assets/svg/right";
// import { useView } from "../api/store";
// import { isCompositeComponent } from 'react-dom/test-utils'

export const ResultAccordion = ({ result }: { result: Result }) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(!open);
  };

  if (!result) {
    return null;
  }

  return (
    <>
      <div className={open ? "accordion open" : "accordion"} onClick={close}>
        <div className="itemHeader">
          <IonText className="strong">
            {result.Election.replace(" A", "").replace(" B", "")}
            {result["Notional TPP"] && <sup>#</sup>}
            {result["Use Former Name"] && <>*</>}
            {result.Byelection && <sup>&dagger;</sup>}
          </IonText>
          <IonText className="strong">{result["Elected Party (TCP)"]}</IonText>
          <IonText>{result["TCP Margin"]?.toFixed(2)}%</IonText>
          <Graph result={result} />
          <span className="arrow">
            <IconRight />
          </span>
        </div>

        <div className="itemBody">
          {result.candidates.map((candidate, ckey) => {
            // eslint-disable-line
            return (
              <IonRow key={ckey}>
                <IonCol size="6">
                  <IonText className="bold">{candidate.name}</IonText>
                </IonCol>
                <IonCol size="3">
                  <IonText
                    className={
                      "bold --party-" +
                      (candidate.party ? candidate.party.toLowerCase() : "")
                    }
                  >
                    {candidate.party}
                  </IonText>
                </IonCol>
                <IonCol size="3">
                  <IonText>{candidate.share.toFixed(2)}</IonText>
                </IonCol>
              </IonRow>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const CountResultAccordion = ({ electorate, election, results }) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(!open);
  };

  results.sort((a, b) => a["Candidate Order"] - b["Candidate Order"]);

  // Group by party and sum of %
  let groupedByParty = useMemo(() => {
    const groupedData = {} as any;
    const finalData = {};

    interface FinalDataT {
      count: number;
      total: number;
    }

    results.forEach((result) => {
      groupedData[result["Candidate Party"]] = [];
      finalData[result["Candidate Party"]] = {} as FinalDataT;
    });

    results.forEach((result) => {
      groupedData[result["Candidate Party"]].push(result);
    });

    Object.entries(groupedData).forEach(([party, data = [] as any]) => {
      let total = 0;
      data.forEach((value) => {
        total = total + value["Candidate Share of Primary Vote"];
      });

      finalData[party].count = data.length;
      finalData[party].total = total.toFixed(2);
    });

    return finalData;
  }, [results]);

  let flaggedResults = useMemo(() => {
    const newData = [];
    let currentParty = results[0]["Candidate Party"];
    let count = 0;
    let originalLength = results.length;

    results.forEach((result, key) => {
      if (result["Candidate Order"] === 1) {
        newData.push({
          ...result,
          "Show Party": true,
        });
      } else if (result["Candidate Party"] !== currentParty) {
        newData.splice(count, 0, groupedByParty[currentParty].total);
        count++;
        newData.push({
          ...result,
          "Show Party": true,
        });
        currentParty = result["Candidate Party"];
      } else {
        newData.push({
          ...result,
          "Show Party": false,
        });
      }

      if (key === originalLength - 1) {
        newData.push(groupedByParty[currentParty].total);
      }

      count++;
    });

    return newData;
  }, [results, groupedByParty]);

  let count = useMemo(() => {
    const countData = {};

    results.forEach((result) => {
      if (result["Candidate Elected?"]) {
        countData[result["Candidate Party"]] = 0;
      }
    });

    results.forEach((result) => {
      if (result["Candidate Elected?"]) {
        countData[result["Candidate Party"]] =
          countData[result["Candidate Party"]] + 1;
      }
    });

    return countData;
  }, [results]);

  return (
    <>
      <div className={open ? "accordion open" : "accordion"}>
        <div className="itemHeader" onClick={close}>
          <IonText>
            {election.replace(" A", "").replace(" B", "")}
            {results[0]["Former Name"] && <>*</>}
            {results[0].Byelection && <sup>&dagger;</sup>}
          </IonText>
          <div style={{ flexGrow: 1 }}>
            <CountGraph electorate={electorate.toLowerCase()} year={election} />
          </div>
          <span className="arrow">
            <IconRight />
          </span>
        </div>

        <div className="itemBody">
          <IonRow>
            <IonCol size="12">
              <IonText style={{ fontSize: "12px" }}>
                Candidates elected{" "}
                {Object.entries(count).map(([party, value], i) => {
                  return (
                    <Fragment key={i}>
                      <span className={`--party-${party.toLowerCase()}`}>
                        {party} {value}
                      </span>
                      {i !== Object.keys(count).length - 1 && <span> / </span>}
                    </Fragment>
                  );
                })}
              </IonText>
            </IonCol>
          </IonRow>
          {flaggedResults.map((candidate, ckey) =>
            typeof candidate === "object" ? (
              // eslint-disable-line
              <Fragment key={ckey}>
                {candidate["Show Party"] && (
                  <IonRow>
                    <IonCol size="12">
                      <IonText
                        className={
                          "bold --party-" +
                          (candidate["Candidate Party"]
                            ? candidate["Candidate Party"].toLowerCase()
                            : "")
                        }
                      >
                        {candidate["Candidate Party"] + " candidates"}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}

                <IonRow>
                  <IonCol size="1">
                    {candidate["Candidate Elected?"] && (
                      <IonText className="bold reversed">
                        {candidate["Candidate Elected?"]}
                      </IonText>
                    )}
                  </IonCol>
                  <IonCol size="8">
                    <IonText className="bold">
                      {candidate["Candidate Name"]}
                    </IonText>
                  </IonCol>
                  <IonCol size="1">
                    {candidate["Candidate Sitting"] && (
                      <IonText className="bold notreversed">
                        {candidate["Candidate Sitting"]}
                      </IonText>
                    )}
                  </IonCol>
                  <IonCol size="2">
                    <IonText>
                      {candidate["Candidate Share of Primary Vote"] + "%"}
                    </IonText>
                  </IonCol>
                </IonRow>
              </Fragment>
            ) : (
              <Fragment key={ckey}>
                <IonRow>
                  <IonCol size="10">
                    <IonText className="bold">
                      Share of vote total (First preferences)
                    </IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText className="bold">{candidate + "%"}</IonText>
                  </IonCol>
                </IonRow>
              </Fragment>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ResultAccordion;
