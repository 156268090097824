import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { MapContainer, GeoJSON, TileLayer } from "react-leaflet";
import "./leafletMap.scss";
import "leaflet/dist/leaflet.css";
import { Geolocation } from "@ionic-native/geolocation";

import { IonFab, IonFabButton, useIonViewWillLeave } from "@ionic/react";
import { IconCenterMap } from "../assets/svg/centerMap";
import { IconResetZoom } from "../assets/svg/resetZoom";

import MapMiniContent from "./MapMiniContent";
import { useView } from "../api/store";

let i = 0;

const LeafletMap = ({ getModalData }) => {
  const [mapSettings, setMapSettings] = useState<any>({
    positionLat: -20.816945607362292,
    positionLon: 145.583610534668,
    maxZoom: 20,
    minZoom: 3,
    defaultZoom: 5,
  });

  let [view, set] = useView();
  // const [geo] = useState({
  //   latitude: 0,
  //   longitude: 0,
  // });

  const [userGeo, setUserGeo] = useState<any>();

  const getUserGeo = async () => {
    let { coords } = await Geolocation.getCurrentPosition();
    if (coords) {
      setUserGeo([coords.latitude, coords.longitude]);
    }
  };

  useLayoutEffect(() => {
    getUserGeo();
  }, []);

  useLayoutEffect(() => {
    if (view.id === "QLD") {
      setMapSettings({
        positionLat: -20.816945607362292,
        positionLon: 145.583610534668,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 5,
      });
    } else if (view.id === "FED") {
      setMapSettings({
        positionLat: -25.610111,
        positionLon: 134.354806,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 4,
      });
    } else if (view.id === "WA") {
      setMapSettings({
        positionLat: -27.6728,
        positionLon: 121.6283,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 5,
      });
    } else if (view.id === "TAS") {
      setMapSettings({
        positionLat: -42.129294,
        positionLon: 146.72752,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 7,
      });
    } else if (view.id === "SA") {
      setMapSettings({
        positionLat: -32.018265,
        positionLon: 134.782478,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 5,
      });
    } else if (view.id === "NT") {
      setMapSettings({
        positionLat: -19.4914,
        positionLon: 132.551,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 5,
      });
    } else if (view.id === "ACT") {
      setMapSettings({
        positionLat: -35.4735,
        positionLon: 149.0124,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 9,
      });
    } else if (view.id === "NSW") {
      setMapSettings({
        positionLat: -31.840233,
        positionLon: 147.113198,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 5,
      });
    } else if (view.id === "VIC") {
      setMapSettings({
        positionLat: -36.915313,
        positionLon: 144.60029,
        maxZoom: 20,
        minZoom: 3,
        defaultZoom: 6,
      });
    }
  }, [view.id]);

  const [popupOpen, setPopupOpen] = useState(false);
  let mapRef = useRef<any>();

  const handleUserGeo = () => {
    let currentMap = mapRef.current;
    if (currentMap != null) {
      currentMap.invalidateSize();
      currentMap.setView(userGeo, 12);
    }
  };

  const resetZoom = () => {
    let currentMap = mapRef.current;
    if (currentMap != null) {
      currentMap.invalidateSize();
      currentMap.setView(
        [mapSettings.positionLat, mapSettings.positionLon],
        mapSettings.defaultZoom
      );
    }
  };

  const clearData = () => {
    set((view) => {
      view.focus = null;
    });
    setPopupOpen(false);
    let currentMap = mapRef.current;

    if (currentMap !== null) {
      resetMapColours();
      currentMap.setView(
        [mapSettings.positionLat, mapSettings.positionLon],
        mapSettings.defaultZoom
      );
    }
  };

  window.onorientationchange = () => {
    let currentMap = mapRef.current;
    if (currentMap === null) return;
    setTimeout(() => {
      currentMap.invalidateSize();
      currentMap.setView(
        [mapSettings.positionLat, mapSettings.positionLon],
        mapSettings.defaultZoom
      );
    }, 200);
  };

  const resetMapColours = () => {
    let currentMap = mapRef.current;
    Object.values(currentMap._layers).forEach((layer: any) => {
      if (typeof layer.feature !== "undefined") {
        layer.setStyle({
          fill: true,
          stroke: true,
          fillOpacity: 2,
        });
      }
    });
  };

  const directZoom = (outerLayer) => {
    let currentMap = mapRef.current;
    if (currentMap === null) return;
    Object.values(currentMap._layers).forEach((layer: any) => {
      if (typeof layer.feature !== "undefined") {
        if (
          layer.feature.properties.NAME.toLowerCase() ===
          outerLayer.feature.properties.NAME.toLowerCase()
        ) {
          currentMap.fitBounds(
            [
              [layer._bounds._southWest.lat, layer._bounds._southWest.lng],
              [layer._bounds._northEast.lat, layer._bounds._northEast.lng],
            ],
            {
              paddingBottomRight: [0, 150],
            }
          );

          setPopupOpen(true);
        }
      }
    });

    setTimeout(() => {
      Object.values(currentMap._layers).forEach((layer: any) => {
        if (typeof layer.feature !== "undefined") {
          if (
            layer.feature.properties.NAME.toLowerCase() !==
            outerLayer.feature.properties.NAME.toLowerCase()
          ) {
            layer.setStyle({
              fill: true,
              stroke: true,
              fillOpacity: 0.2,
            });
          } else {
            layer.setStyle({
              fill: true,
              stroke: true,
              fillOpacity: 1,
            });
          }
        }
      });
    }, 100);
  };

  useEffect(() => {
    let currentMap = mapRef.current;

    if (currentMap != null && view.focus) {
      Object.values(currentMap._layers).forEach((layer: any) => {
        if (typeof layer.feature !== "undefined") {
          if (
            layer.feature.properties.NAME.toLowerCase() ===
            view.focus.toLowerCase()
          ) {
            directZoom(layer);
          }
        }
      });
    }
  }, [view.focus]);

  useIonViewWillLeave(() => {
    clearData();
  });

  const resizeLoadMap = () => {
    setTimeout(() => {
      let currentMap = mapRef.current;
      if (currentMap != null) {
        currentMap.invalidateSize();
        currentMap.setView(
          [mapSettings.positionLat, mapSettings.positionLon],
          mapSettings.defaultZoom
        );
      }
    }, 1000);
  };

  useEffect(() => {
    resizeLoadMap();
  }, [mapSettings]);

  return (
    <MapContainer
      zoomControl={false}
      minZoom={mapSettings.minZoom}
      maxZoom={mapSettings.maxZoom}
      // gestureHandling={true}
      center={[mapSettings.positionLat, mapSettings.positionLon]}
      zoom={mapSettings.defaultZoom}
      ref={mapRef}
      whenReady={resizeLoadMap}
    >
      <TileLayer
        url="https://api.mapbox.com/styles/v1/aaa-maps/ckap3npu2032y1mnxehydogsj/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        attribution="Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a><br>Contributors <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a> | Imagery © <a href='https://www.mapbox.com/'>Mapbox</a>"
        maxZoom={20}
        id="mapbox.streets"
        accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
      />

      {view.id === "QLD" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckfozfrjb0ldc1apmqgp2d6ao/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "WA" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckhxy309y12f619lkptxtavor/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "FED" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckg03akt90j8v19notduf31c8/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "TAS" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckn5jijwy0m5f17o21b7zg39v/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "SA" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckpf4jtmc08wx18pg9gd1bln7/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "NT" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/cksic04ph8abx17mj8emy2z2v/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "ACT" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckspn1efv0fc417pgcudi7jwm/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "NSW" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/ckvljwxz642mg14tgenyxxxjk/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      {view.id === "VIC" && (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/aaa-maps/cl9f45my6000114mr3max25n3/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
          attribution=""
          maxZoom={20}
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoiYWFhLW1hcHMiLCJhIjoiY2thcDNoOWVxMjl1YjMxbG1xcng5dWFrbiJ9.VTtatWklQzVZ-X-l2kg1Gw"
        />
      )}

      <GeoJSON
        key={i++}
        bubblingMouseEvents={false}
        // @ts-ignore
        data={view.electorates}
        onEachFeature={(feature, layer) => {
          // @ts-ignore
          layer.setStyle({
            fillOpacity: 1,
            weight: 1,
            color: "#ffffff",
            fillColor:
              view.parties.find(
                (party) => party.Party === feature.properties.PARTY
              )?.Colour || "#666666",
          });

          layer.on({
            click: () => {
              directZoom(layer);
              set((view) => {
                view.focus = feature.properties.INFO.Electorate;
              });
            },
          });
        }}
      />

      <IonFab vertical="top" horizontal="end" slot="fixed">
        <IonFabButton color="dark" onClick={resetZoom} className="reset-zoom">
          <IconResetZoom />
        </IonFabButton>

        {userGeo && (
          <IonFabButton color="dark" onClick={handleUserGeo}>
            <IconCenterMap />
          </IonFabButton>
        )}
      </IonFab>
      {popupOpen && view.focus && (
        <MapMiniContent onClose={clearData} onExpand={getModalData} />
      )}
    </MapContainer>
  );
};

export default LeafletMap;
