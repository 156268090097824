import React, { Fragment, useMemo } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonText,
  IonHeader,
  IonToolbar,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonPage,
} from "@ionic/react";
import "./MapModal.scss";
import { IconBack } from "../assets/svg/back";
import ModalTab from "./ModalTab";
import InsightIcon from "../components/InsightIcon";
import { useView } from "../api/store";
import { useHistory, useRouteMatch } from "react-router";
import { slugify } from "../utils/string/slugify";
import { Link } from "react-router-dom";
import { Graph, CountGraph } from "./graph/Graph";
import { IconClose } from "../assets/svg/close";
import { CountView, View } from "../api/data-types";

const MapModalIncumbents = () => {
  let history = useHistory();
  let match = useRouteMatch<{
    electorate: string;
    page: string;
    tab: string;
  }>();
  let electorate = match.params.electorate;
  const [view] = useView((view) => view as View);
  let incumbents = useMemo(() => {
    // @ts-ignore
    return (view?.mode === "default" ? view.incumbents : view.results).find(
      (record) => slugify(record.Electorate) === electorate
    );
  }, [view, electorate]);

  let priority = useMemo(() => {
    return view.priorities.find(
      (priority) => slugify(priority.Electorate) === electorate
    );
  }, [view, electorate]);

  let year = useMemo(() => {
    return view.results.find((result) => {
      return slugify(result.Electorate) === electorate.toLowerCase();
    })?.Election;
  }, [view, electorate]);

  const hasDetials =
    priority &&
    (priority.Description ||
      priority.History ||
      priority["Insight High Fuel Cost"] ||
      priority["Insight High Traffic Congestion"]);
  return (
    <IonPage className="electorate-modal">
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <Link className="icon-back" to={`/${match.params.page}`}>
              <IconBack />
            </Link>
            <IonTitle>Electorate</IonTitle>
            <Link className="icon-close" to={`/${match.params.page}`}>
              <IconClose />
            </Link>
          </IonToolbar>
        </IonHeader>

        <IonContent className="modal-content">
          <IonGrid>
            <IonRow>
              <IonCol size="8">
                <IonTitle>
                  {incumbents["Electorate"]}, {incumbents["State"]}
                </IonTitle>
              </IonCol>
              <IonCol size="4">
                <div className="header-insights">
                  {priority?.["Insight High Traffic Congestion"] && (
                    <InsightIcon type="traffic" />
                  )}

                  {priority?.["Insight High Fuel Cost"] && (
                    <InsightIcon type="fuel" />
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="8">
                <IonText className="sm light flex">
                  Incumbent MP ({year}){" "}
                  {incumbents["Not recontesting"] && <> - Not recontesting</>}
                </IonText>
                <IonText className="lg clear flex">
                  {incumbents["Elected Candidate (TCP) [Incumbent]"]} -{" "}
                  {incumbents["Elected Party (TCP) [Incumbent]"]}
                </IonText>
              </IonCol>
              <IonCol size="4">
                <IonText className="sm right light flex">Margin</IonText>
                <IonText className="lg right clear flex">
                  {incumbents["TCP Margin [Incumbent]"].toFixed(2)}%
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <Graph result={incumbents} />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonSegment
            scrollable
            color="primary"
            mode="md"
            value={match.params.tab || "results"}
            onIonChange={(e) =>
              history.replace(
                `/${match.params.page}/electorate/${electorate}/${e.detail.value}`
              )
            }
          >
            <IonSegmentButton value="results">Results</IonSegmentButton>
            {!!hasDetials && (
              <IonSegmentButton value="details">Details</IonSegmentButton>
            )}
            {(!!view.candidates.length || view.id !== "FED") && (
              <IonSegmentButton value="candidates">Candidates</IonSegmentButton>
            )}
            {priority?.Priorities && (
              <IonSegmentButton value="priorities">Priorities</IonSegmentButton>
            )}
          </IonSegment>

          <ModalTab
            hasDetails={hasDetials}
            electorate={electorate}
            parentPath={`/${match.params.page}/electorate/${electorate}`}
          />
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

const MapModalCount = () => {
  let history = useHistory();
  let match = useRouteMatch<{
    electorate: string;
    page: string;
    tab: string;
  }>();
  let electorate = match.params.electorate;
  const [view] = useView((view) => view as CountView);

  const relevantResults = useMemo(
    () =>
      view.results.filter(
        (record) => slugify(record.Electorate) === electorate
      ),
    [electorate, view.results]
  );
  const ELECTORATE = relevantResults[0].Electorate;
  const STATE = relevantResults[0].State;

  const maxYear = useMemo(
    () =>
      view.results.reduce(
        (last, next) => Math.max(parseInt(next.Election.substring(1, 4)), last),
        0
      ),
    [view.results]
  );
  const results = {};
  const elec = electorate.toLowerCase();
  // const current = view.results
  //   .filter(
  //     (result) =>
  //       parseInt(result.Election.substring(1, 4)) === maxYear &&
  //       result.Electorate.toLowerCase() === elec &&
  //       !result.Election.includes("A")
  //   )
  //   .sort((a, b) => {
  //     return a["Candidate Order"] - b["Candidate Order"];
  //   })
  //   .forEach((result) => {
  //     if (result["Candidate Sitting"]) {
  //       results[result["Candidate Party"]] =
  //         (results[result["Candidate Party"]] || 0) + 1;
  //     }
  //   });

  let priority = useMemo(
    () =>
      view.priorities.find(
        (priority) => slugify(priority.Electorate) === electorate
      ),
    [view, electorate]
  );

  // let year = useMemo(
  //   () =>
  //     view.results.reduce(
  //       (last, current) => Math.max(last, Number(current.Election)),
  //       0
  //     ),
  //   [view, electorate]
  // );

  const hasDetials =
    priority &&
    (priority.Description ||
      priority.History ||
      priority["Insight High Fuel Cost"] ||
      priority["Insight High Traffic Congestion"]);

  return (
    <IonPage className="electorate-modal">
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <Link className="icon-back" to={`/${match.params.page}`}>
              <IconBack />
            </Link>
            <IonTitle>Electorate</IonTitle>
            <Link className="icon-close" to={`/${match.params.page}`}>
              <IconClose />
            </Link>
          </IonToolbar>
        </IonHeader>

        <IonContent className="modal-content">
          <IonGrid>
            <IonRow>
              <IonCol size="8">
                <IonTitle>
                  {ELECTORATE}, {STATE}
                </IonTitle>
              </IonCol>
              <IonCol size="4">
                <div className="header-insights">
                  {priority?.["Insight High Traffic Congestion"] && (
                    <InsightIcon type="traffic" />
                  )}

                  {priority?.["Insight High Fuel Cost"] && (
                    <InsightIcon type="fuel" />
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <IonText style={{ fontSize: "12px" }}>
                  Candidates elected{" "}
                  {Object.entries(results).map(([party, count], i) => {
                    return (
                      <Fragment key={i}>
                        <span className={`--party-${party.toLowerCase()}`}>
                          {party} {count}
                        </span>
                        {i !== Object.keys(results).length - 1 && (
                          <span> / </span>
                        )}
                      </Fragment>
                    );
                  })}
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <CountGraph electorate={elec} year={maxYear} />
                <IonText style={{ fontSize: "12px" }}>
                  Quota breakdown after distribution
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonSegment
            scrollable
            color="primary"
            mode="md"
            value={match.params.tab || "results"}
            onIonChange={(e) =>
              history.replace(
                `/${match.params.page}/electorate/${electorate}/${e.detail.value}`
              )
            }
          >
            <IonSegmentButton value="results">Results</IonSegmentButton>
            {!!hasDetials && (
              <IonSegmentButton value="details">Details</IonSegmentButton>
            )}
            {(!!view.candidates.length || view.id !== "FED") && (
              <IonSegmentButton value="candidates">Candidates</IonSegmentButton>
            )}
            {priority?.Priorities && (
              <IonSegmentButton value="priorities">Priorities</IonSegmentButton>
            )}
          </IonSegment>

          <ModalTab
            hasDetails={hasDetials}
            electorate={electorate}
            parentPath={`/${match.params.page}/electorate/${electorate}`}
          />
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

const MapModal = ({ match }) => {
  let [view] = useView();
  // let electorate = match.params.electorate;

  if (view.mode === "default") {
    return <MapModalIncumbents />;
  } else {
    return <MapModalCount />;
  }
};

export default MapModal;
